.cms-index-index {
    .bluefoot-column {
        width: 100%;
    }

    .bluefoot-slider {
        margin-top: -10px;
        margin-bottom: 0;
    }
}

.bluefoot-slide {
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px !important;
    position: relative;
    z-index: 1;

    @include media('<=phone') {
        height: 180px !important;
    }

    .slider__foregroundimage {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;

        @include media('<=phone') {
            display: none;
        }
    }

    .bluefoot-slide-content {
        @include media('<=phone') {
            display: block;
        }

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;

        h2 {
            @extend %h1;
            color: $gold;
            width: 50%;
            margin-bottom: 35px;

            .custom-template-gp & {
                margin-bottom: 0;
            }

            @include media('<=phone') {
                margin-bottom: 10px;
                line-height: 28px;
                width: 70%;
            }
        }

        p {
            .custom-template-gp & {
                margin: 0;
            }
        }

        .slider-button {
            @extend %button-white;
            @extend %button-white--more;
            background: $white;
            @include fontWeightBold();
            @include media('>phone') {
                font-size: 18px;
                border-radius: 30px;
                padding-top: 10px;
                padding-bottom: 10px;
                &:after {
                    height: 60px;
                    right: 10px;
                }
            }
            @include media('<=phone') {
                border-radius: 30px;
                padding: 0px 40px 0 15px;
            }
        }
    }

    .bluefoot-wrapper {
        margin-top: 0;
        position: relative;
        height: 100%;
    }
}
