$layout-column-main__sidebar-offset: 2%;
$layout-column__additional-sidebar-offset: $layout-column-main__sidebar-offset;

//
//  Common
//  _____________________________________________

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

.columns {
  @include lib-layout-columns();

  .column.main {
    @extend .abs-add-box-sizing;
    padding-bottom: $indent__xl;
    flex-basis: 100%;
    flex-grow: 1;
    order: 1;
  }

  .sidebar-main {
    @extend .abs-add-box-sizing;
    width: 100%;
  }

  .sidebar-additional {
    @extend .abs-add-box-sizing;
    width: 100%;
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
  .breadcrumbs,
  .footer-columns--wrapper,
  .page-wrapper > .widget,
  .page-wrapper > .page-bottom,
  .block.category.event,
  .top-container {
    padding-left: $layout__width-xs-indent;
    padding-right: $layout__width-xs-indent;
  }

  .page-main {
    .account &,
    .cms-privacy-policy & {
      padding-top: 41px;
      position: relative;
    }
  }
}

div {
  box-sizing: border-box;
  display: block;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .navigation,
  .page-main,
  body.checkout-onepage-success 
  .breadcrumbs,
  .footer-columns--wrapper,
  .page-wrapper > .widget,
  .page-wrapper > .page-bottom,
  .block.category.event,
  .header__usps,
  .success__aside,
  .success-page,
  .product-page-top-wrapper,
  .top-container {
    @include container-size;
    width: 100%;
  }

  .columns {
    display: block;
  }

  .column.main {
    @extend .abs-add-box-sizing-desktop;
    @include lib-layout-columns__main();
    min-height: 300px;
  }

  .sidebar-main {
    @extend .abs-add-box-sizing-desktop;
    @include lib-layout-columns__left();
    padding-right: 0;
  }

  .page-layout-2columns-right .sidebar-main {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .sidebar-additional {
    @extend .abs-add-box-sizing-desktop;
    @include lib-layout-columns__right();
    clear: right;
    padding-left: 0;
  }

  .page-layout-2columns-left {
    .sidebar-main {
      width: 100%;
    }

    .sidebar-additional {
      clear: left;
      float: left;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }

  .panel.header {
    padding: 10px 0;
  }
}

@include media('<=tablet') {
  padding: 0;
}

.cms-index-index {
  .page-main {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
}

main {
  //  padding: 0 15px;
}

.cms-index-index .bluefoot-column-6 {
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.columns .column.main {
  @media screen and (max-width: 767px) {
    padding-bottom: 0;
  }
}

body.cms-page-view .bluefoot-column-8,
body.cms-page-view .bluefoot-column-4 {
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.bluefoot-column-4 .sos-box {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

body.cms-page-view {
  .bluefoot-row {
    h1,h2,h3,h4,h5,h6 {
      margin-top: 0;
    }
  }
}

