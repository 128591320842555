/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _tabs contains {less} for Tabs
 *
 */

.#{$prefix}-tabs {
  .bluefoot-accordion-link {
    cursor: pointer;
    margin-bottom: 0;
    padding: $bf-margin;
    box-sizing: border-box;
    line-height: 1.4em;
    border-bottom: 1px solid $bf-border_color;
    &:after {
      content: '+';
      @extend %#{$prefix}-icons;
      float: right;
      font-size: 1.4em;
    }
  }

  .#{$prefix}-tabs-navigation {
    border-bottom: 1px solid $bf-border_color;
    margin: 0;
    padding: 0;
    li {
      float: left;
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
}

.#{$prefix}-tab {
  height: $common_height;
  line-height: $common_height;
  border: 1px solid $bf-border_color;
  border-bottom: none;
  border-right: none;
  background: $light_grey;
  padding: 0 $bf-margin;
  display: block;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    left: 0;
    right: 0;
    bottom: -1px;
    border-bottom: 1px solid white;
    position: absolute;
    display: none;
  }
  &.last {
    border-right: 1px solid $bf-border_color;
  }
  &.active,
  &:hover {
    background: white;
  }
  &.active:after {
    display: block;
  }
}

.#{$prefix}-tab-inner {
  padding: $bf-margin 0;
  box-sizing: border-box;
}

.#{$prefix}-tab-content {
  display: none;
  &.active {
    display: block;
  }
}

@include media(">phone") {
  .#{$prefix}-tab-inner {
    display: block !important;
  }
}

@include media("<=phone") {
  .#{$prefix}-tab-content {
    display: block;
  }

  .#{$prefix}-tab-inner {
    padding: $bf-margin;
    display: none;
  }
}
