/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _image contains {less} for image blocks
 *
 */
.#{$prefix}-image {
  a {
    width: auto;
    display: inline-block;
  }
  img {
    max-width: 100%;
    display: block;
  }
  figcaption {
    text-align: center;
    margin-top: $bf-margin;
  }
}
