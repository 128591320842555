.wrapper404 {
  background-color: $desert-storm;
  margin-top: -10px;
  padding: 35px 0;
  .catalogsearch-result-index &{
    margin-top: 0;
  }
  section.sos-box{
    border: none;
    position: relative;
    &:after {
      @extend %gaussian-shadow;
    }

    a{
      color: $default-font-color;
    }

    div.sos-box--inside{
      padding: 20px;
    }

    div.sos-box--inside a.faq{
      display: none;
    }
  }
  .bluefoot-textarea h1,
  h1 {
    color: $christi;
    font-size: 80px;
    line-height: 100px;
    margin: 0 0 30px 0;
    @include media("<=tablet"){
      font-size: 60px;
      line-height: 90px;
    }
  }

  p {
    @extend %p--intro;
  }

  .bluefoot-driver {
    background-color: $white;
    border-radius: 3px;
    margin: 50px 0 0 0;
    position: relative;

    &:after {
      @extend %gaussian-shadow;
    }

    a {
      height: 330px;
      @include media("<=desktop") {
        height: 310px;
      }
      @include media("<=tablet") {
        height: 280px;
      }
      @include media("<=phone") {
        height: 230px;
      }

      align-items: center;
      background: $white;
      border-radius: 3px;
      display: flex;
      position: relative;
      width: 100%;
      z-index: 3;
      img {
        margin: -50px auto 0 auto;
        width: 120px;
      }
    }
    .bluefoot-driver-text {
      @extend %product-title;
      @extend %product-title--centered;
      position: relative;
      background-color: $white;
      color: rgba($black, .5);
      padding: 0;
      position: absolute;
      bottom: 30px;
      left: 0;
    }
  }

  @include media("<=tablet"){
    .bluefoot-column-9,
    .bluefoot-column-3{
      lost-column: 1/2 2;
    }
  }
}

/* for every class starting with bluefoot-column- set width to 100% */
.columns-2 div[class*='bluefoot-column-'] {

  @include media("<=phone"){
    width: 100%;
    margin: 0;
  }
}

@media not all and (min-resolution:.001dpcm) { 

        .columns-2 div[class*=bluefoot-column-]:nth-child(1n) { 
            flex: 1 0;

            @media (max-width: 767px) {
              flex: 1 0 100%; 
            } 
        }
    
}


@media not all and (min-resolution:.001dpcm) and (max-width: 767px) {
  .columns-2 div[class*=bluefoot-column-]:nth-child(1n) { 
    flex: 1 0 100%;
  }
}  


