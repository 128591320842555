/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * Core {less} file for the BlueFoot Magento Extension
 *
 */

/*-- Core Styles --*/
@import 'core/var';
@import 'core/fonts';
@import 'core/common';

/*-- Page Builder Styles --*/
@import 'pagebuilder/structural/import';
@import 'pagebuilder/blocks/import';

/*-- Core Apps --*/
@import 'apps/import';
