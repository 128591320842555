.review-ratings {
  float: inherit;
  margin: 25px 0;
  width: 100%;
  max-width: 100%;
  .rating-result {
    margin-left: 0;
    width: 121px;
    &:before {
      background: url('../svg/stars/star-blank.svg') no-repeat left;
      content: '';
      height: 20px;
      width: 100%;
    }
    > span:before {
      background: url('../svg/stars/star-full.svg') no-repeat left;
      content: '';
      height: 20px;
    }
  }
  .author-name {
    font-size: 22px;
    font-weight: 300;
  }

  .rating-summary {
    float: left;
    margin: 3px 25px 0 0;
    @include media("<=phone"){
      float: inherit;
      margin-bottom: 15px;
    }
  }
}

.block-content {
  color: rgba(0, 0, 0, 0.5);

  .review-content {
    padding: 0 0 20px 0;
    margin: 0;
    font-size: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    .review-title {
      font-size: 18px;
      @include fontWeightBold();
    }
  }

  a.more-reviews {
    @extend %button-ghost-primary;
    @extend %button-ghost-primary--small;
    margin-top: 38px;
  }
}

.review-form .action.submit.primary {
  @extend %button-primary;
  &:hover {
    background: #63a70a;
    color: white;
  }
}

.review-control-vote:before {
  background: url('../svg/stars/star-blank.svg') no-repeat left;
  content: '';
  height: 20px;
  width: 120px;
}

.review-control-vote label[class*='rating-']:before {
  background: url('../svg/stars/star-full.svg') no-repeat left;
  color: transparent;
}

.review-field-ratings .label .span {
  font-size: 16px;
}

.review-add {
  padding: 25px 0;
}

#Product_rating_label {
  display: none;
}

.review-field-ratings .review-field-rating .control {
  padding: 8px 0 0 0;
}

.review-control-vote label:before {
  font-size: 28px;
  height: 20px;
  letter-spacing: -4px;
  line-height: 20px;
}

.reviews-container {
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 2s ease-in;

  &.grow {
    max-height: 10000px !important;
  }
}

.review {
  &.invisible {
    display: none;
    &.visible {
      display: block;
    }
  }
}
