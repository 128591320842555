.filter-options {
  .filter-block-container {
    border-bottom: 1px solid $grey-border;
    position: relative;
    &:after {
      @extend %filter-min;
      content: '';
      display: block;
      height: 12px;
      position: absolute;
      right: 0;
      top: 6px;
      width: 12px;
      z-index: 1;
    }
    &.closed {
      &:after {
        @extend %filter-plus;
      }
    }

    .filter-options-title {
      @extend %h5;
      cursor: pointer;
      font-weight: normal;
      margin: 12px 0;
      position: relative;
      z-index: 2;
      span.heading {
        padding-right: 2px;
      }
    }

    .filter-options-content {
      margin-bottom: 0;
      height: 100%;
      max-height: 1000px;
      padding-bottom: 25px;

      ol.items {
        li.item {
          //&:last-child{
          //  padding-bottom: 25px;
          //}

          a,
          span.label {
            @extend %light-65;
          }
        }
      }
    }

    &.closed {
      .filter-toggle-icon {
        cursor: pointer;

        &:after {
          transform: rotate(90deg);
        }
      }

      .filter-options-content {
        padding: 0;
        max-height: 0px;
        opacity: 0;
        transition: max-height 0.3s ease-in, opacity 0.3s ease-in,
          padding 0.3s ease-in;
      }
    }
  }
}

.am_shopby_apply_filters .am-show-button.-horizontal{
  display: none;
}

.amshopby-more-button{
  @extend %button-regular-init;
  background: transparent;
  border-radius: 32px;
  color: $cod-gray;
  display: inline-block;
  font-weight: 400;
  line-height: 32px;
  margin-top: 10px;
  opacity: .65;
  padding: 0 20px;
}