.login-container {
  margin-top: 60px;
  lost-center: 760px;
  @include media('<=tablet') {
    width: 100%;
  }

  .block {
    lost-column: 1/2;
    @include media('<=tablet') {
      lost-column: 1/1;
      padding: 0 20px;
    }
    .block-title {
      border-bottom: none;
      strong {
        @extend %h3;
        @extend %h3--primary;
      }
    }
  }

  .field.note {
    display: none;
  }

  fieldset.fieldset.login {
    label.label {
      display: block;
      width: 100%;
      text-align: left;
      float: none;
      @extend %label;
      @extend %label--small;
    }
    .control {
      width: 100%;
      input {
        border-color: $gainsboro;
      }
    }
    .actions-toolbar {
      margin-left: 0;
    }
    &:after {
      content: none;
    }
  }

  .block.block-customer-login {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    margin-right: 0;
    padding-right: 40px;
  }
  .block-new-customer {
    ul {
      margin: 0;
      padding: 0;
      li {
        color: rgba($mineshaft, 0.65);
        font-size: 16px;
        @include fontWeightLight();
        list-style-type: none;
        position: relative;
        padding-left: 26px;
        margin-bottom: 15px;
        &:before {
          content: '';
          @extend %check;
          width: 16px;
          height: 16px;
          display: inline-block;
          opacity: 1;
          position: absolute;
          left: 0;
          top: 2px;
        }
      }
    }
  }
  .actions-toolbar {
    .primary {
      @include media ('<=phone') {
        text-align: left;
      }
      a.action.create.primary,
      .action.login.primary {
        @extend %button-primary;
        border: none;
        @include media ('<=phone') {
          width: auto;
          float: left;
        }
      }
    }
    .secondary {
      display: block;
      text-align: left;
      margin-top: 10px;
      a.action.remind {
        font-size: 13px;
        @include fontWeightLight();
        color: $cod-gray;
        opacity: 0.5;
        text-decoration: underline;
      }
      @include media('<=phone') {
        float: right;
        margin-top: 20px;
      }
    }
  }
}
