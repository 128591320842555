/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _include includes all the core block type {less} files
 *
 */

@import 'image';
@import 'video';
@import 'driver';
@import 'slider';
