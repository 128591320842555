.breadcrumbs--wrapper {
  border-bottom: 1px solid $desert-storm;

  .breadcrumbs strong{
    line-height: 19px;
  }

  .breadcrumb {
    @include container-size();
    @include media('>tablet') {
      width: 100%;
    }
    padding: 5px 0;

    @include media('<=phone') {
      padding: 20px 15px;
    }

    div {
      display: inline;

      a,
      span {
        font-size: 13px;
        line-height: 25px;
        color: rgba($cod-gray, 0.5);
        @include fontWeightLight();
      }

      &:not(:last-child):after {
        @extend %chevron-breadcrumb;
        content: '';
        height: 6px;
        padding: 0 10px;
        position: relative;
        bottom: 1px;
        width: 4px;
      }
    }
  }
}

@include media('<=phone'){
  .catalog-category-view,
  .catalog-product-view{
    .breadcrumbs--wrapper{
      display: none;
    }
  }
}


.breadcrumbs {
  height: 36px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f8f8f8;
}