.customerservice-menu {
  @include media('<=tablet') {
    display: none;
  }
  float: right;
  border-radius: 35px;
  background-color: transparent;
  height: 50px;
  margin: 5px;
  padding-right: 25px;
  position: relative;
  &:hover {
    .customerservice-menu--anouk {
      width: 214px;
    }
    > a {
      color: $christi;
    }
    > a:after {
      @extend %chevron-green-right;
    }
    .sos-box-wrapper {
      opacity: 1;
      visibility: visible;
    }
  }
  &--anouk {
    background-color: $white;
    border-radius: 35px;
    content: '';
    display: block;
    float: left;
    height: 50px;
    margin: 0 10px 0 0;
    overflow: hidden;
    position: absolute;
    transition: width 0.2s;
    width: 50px;
    z-index: 12;
  }

  &--anouk {
    &::before {
      background: url('../images/anouk.png') no-repeat center;
      background-size: 74px 110px;
      position: relative;
      height: 110px;
      left: -6px;
      top: 1px;
      width: 74px;
      z-index: 9;
      display: inline-block;
      content: '';
    }
  }
  > a {
    border-radius: 25px;
    display: inline-block;
    color: $white;
    @include fontWeightBold();
    font-size: 15px;
    line-height: 52px;
    padding: 0 0 0 65px;
    position: relative;
    z-index: 15;
    &:hover {
      text-decoration: none;
    }
    &:after {
      @extend %chevron-white-right;
      background-size: 7px;
      content: '';
      display: block;
      height: 10px;
      position: absolute;
      top: 20px;
      transform: rotate(90deg);
      right: -20px;
      width: 7px;
    }
  }
  .sos-box-wrapper {
    padding-top: 13px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
    transition-delay: .1s;
  }
}

body.checkout-index-index .customerservice-menu {
  margin: 5px 0;
  &:hover .customerservice-menu--anouk {
    z-index: 0;
  }

  section.sos-box {
    left: inherit;
    right: -20px;
    &:before {
      left: 50%;
    }
    &:after {
      left: calc(50% - 1px);
    }
    div.sos-box--inside {
      z-index: 2;
    }
  }
}
body.cms-no-route .bluefoot-wrapper {
  .customerservice-menu {
    @include media('<=tablet') {
      display: none;
    }
    float: none;
    border: none;
    position: inherit;
    padding: 0;
    margin: 0;
    height: inherit;
    border-radius: 0;

    .customerservice-menu--anouk,
    > a {
      display: none;
    }

    .sos-box-wrapper {
      opacity: 1;
      visibility: visible;
    }
  }
}
