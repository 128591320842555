@mixin container-size() {
  @lost gutter 40px;
  @lost rounder 100;

  @include media("<=phone") {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
  }

  @include media(">=phone", "<=tablet") {
      max-width: 750px;
      margin-left: auto;
      margin-right: auto;
  }

  @include media(">=tablet", "<=desktop") {
      max-width: 970px;
      margin-left: auto;
      margin-right: auto;
  }

  @include media(">=desktop", "<=large") {
      max-width: 1140px;
      margin-left: auto;
      margin-right: auto;
  }
  @include media(">=large") {
      max-width: 1440px;
      margin-left: auto;
      margin-right: auto;
  }
}
