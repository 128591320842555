
/* Snowdog hack to set * for required fields with _required class and .required, instead of only the  .required class. */
.fieldset > .field.required > .label:after,
.fieldset > .fields > .field.required > .label:after,
.fieldset > .field._required > .label:after,
.fieldset > .fields > .field._required > .label:after {
    content: '*';
    color: #e02b27;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: 'Arial';
    font-size: 18px;
    margin: -2px 0px 0px 3px;
}

.fieldset > .field:not(.choice) > .label {
    text-align: left;
}
