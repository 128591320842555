body.catalog-category-view,
body.catalogsearch-result-index {
    .main-content-wrapper {
        background: linear-gradient(to left, $desert-storm 50%, $white 50%);
        @include media('<=tablet') {
            background: $desert-storm;
        }
        position: relative;
    }

    .page-top-wrapper {
        .page-title-wrapper {
            padding: 0;
            @include media('<=phone') {
                padding: 0 15px;
            }

            &.category-banner-heading {
                padding: 0 15px 0;
            }

            .category-title-small {
                color: $cod-gray;
                font-size: 22px;
                @include fontWeightLight();
            }

            .category-cms img {
                width: 100%;
            }

            .category-image {
                position: absolute;
                top: 5px;
                right: 0;
                display: block;

                img {
                    z-index: 3;
                    position: relative;
                }

                @include media('<=phone') {
                    display: none;
                }
            }
        }
    }
}

body.catalog-product-view {
    &.page-layout-2columns-left .column.main {
        float: inherit;
        width: 100%;
    }

    .page-main {
        max-width: 100%;
    }
}

.product-description {
    background-color: $desert-storm;
    position: relative;
    z-index: 2;
    clear: both;

    .product.attribute.description {
        @include container-size;
        padding: 60px 0;
        @extend %p--intro;
        width: 100%;
        @include media('<=tablet') {
            padding: 60px 30px;
        }

        .value {
            font-size: 14px;
            @include media('>=tablet') {
                lost-column: 2/3;
            }

            .content[aria-expanded='false'] {
                position: relative;

                &:after {
                    position: absolute;
                    bottom: -1px;
                    width: 100%;
                    display: block;
                    content: '';
                    height: 1px;
                    box-shadow: 0 0 120px 70px $desert-storm;
                }
            }

            .content[aria-expanded='true'] {
                margin-bottom: 15px;
            }

            a[data-readmore-toggle] {
                @extend %button-ghost-primary;
                @extend %button-ghost-primary--small;
                margin-top: 20px;
            }
        }
    }
}

.product {
    &.data.items {
        > .item.content {
            border: none;
        }
    }

    &.info.detailed {
        clear: both;
        margin-bottom: 0;
        position: relative;
        z-index: 2;
    }

    &--sticky-bar {
        background-color: $white;
        padding-left: 15px;
        padding-right: 15px;

        &:after {
            background-image: linear-gradient(0deg, rgba($black, 0) 0%, $black 100%);
            content: '';
            display: block;
            height: 3px;
            margin: 0 -15px;
            opacity: 0.1;
            width: calc(100% + 30px);
        }

        .sticky-bar-items {
            @include container-size;
            align-content: flex-start;
            display: flex;
            height: 60px;

            .sticky-title {
                a {
                    @extend %tabs;
                    line-height: 60px;
                    padding: 0 60px 0 0;

                    &:hover {
                        @extend %tabs--active;
                        line-height: 60px;
                        text-decoration: none;
                    }

                    &.active {
                        @extend %tabs--active;
                        line-height: 60px;
                    }

                    span.counter {
                        &:before {
                            content: '(';
                        }

                        &:after {
                            content: ')';
                        }
                    }
                }
            }
        }
    }

    &--sticky-content {
        .sticky-content-items {
            background-color: $desert-storm;
            padding-left: 15px;
            padding-right: 15px;
            @minclude media('<=tablet') {
                padding: 0 30px;
            }
            position: relative;
            @for $i from 4 through 1 {
                &:nth-last-child(#{$i}) {
                    position: relative;
                    z-index: $i;
                }
            }

            &:nth-child(odd) {
                background-color: $white;
            }

            .anchor {
                position: relative;
                z-index: 0;
                width: 100%;
                height: 10px;
                top: -140px;
                @include media('<=tablet') {
                    top: -215px;
                }
            }

            .sticky-content {
                @include container-size;

                padding: 0;
                @include media('<=phone') {
                    padding: 30px 15px;
                }

                > div {
                    @include media('>=tablet') {
                        width: calc(100% * 2 / 3 - 13.333333333333332px);

                        &:nth-child(3n+1) {
                            clear: both;
                        }

                        &:last-child {
                            clear: both;
                        }
                    }
                }

                ul.list-unstyled {
                    padding: 0;

                    li {
                        @include clearfix;
                        margin: 0;

                        a.file {
                            @extend %p;
                            @extend %p--light;
                            border-top: 1px solid $mercury;
                            display: block;
                            line-height: 30px;
                            padding: 15px 0;

                            span {
                                @extend %button-ghost-default;
                                @extend %button-ghost-default--download;
                                float: right;
                                text-align: center;
                                width: 180px;
                                @include media('<=phone') {
                                    display: none;
                                }
                            }
                        }

                        &:last-child a.file {
                            border-bottom: 1px solid $mercury;
                        }
                    }
                }

                h3,
                .block-title strong {
                    @extend %h3;
                    @extend %h3--primary;
                    display: block;
                    font-weight: 200;
                    margin-bottom: 30px;
                    margin-top: 50px;
                }

                .product.info.detailed .additional-attributes {
                    height: 200px;
                }

                .collapsable-table {
                    .specification-row {
                        margin-bottom: 40px;
                        display: flex;
                        width: 100%;
                        @include media('<=phone') {
                            margin-bottom: 20px;
                        }

                        span {
                            @include font-size($h4-size);
                            color: rgba($cod-gray, 0.7);
                            width: 60%;
                            @include fontWeightLight();

                            &.label {
                                color: rgba($cod-gray, 0.4);
                                display: inline-block;
                                width: 40%;
                                margin-right: 15px;
                                @include media('<=phone') {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                a[data-readmore-toggle] {
                    @extend %button-ghost-primary;
                    @extend %button-ghost-primary--small;
                }
            }
        }
    }
}

.product.data.items {
    margin-bottom: 0;
}

.additional-attributes-wrapper {
    height: 100%;

    .collapsable-table {
        overflow: hidden;
    }
}

.block-title.title .ghost-button {
    @include media('<=phone') {
        display: none;
    }
    @extend %button-ghost-primary;
    @extend %button-ghost-primary--more;
    clear: both;
    float: right;
    position: relative;
    top: -7px;
}

.columns .column.main {
    padding-bottom: 0;

    dl.block {
        .item {
            a {
                color: $christi;
            }
        }
    }
}

.category-view {
    position: absolute;
    right: 0;
    top: -190px;
    z-index: 1;
}

.product-description:empty {
    padding: 0 !important;
}

.select2-search--dropdown {
    display: none;
}

.select2-results__option {
    background-color: $white !important;

    &--highlighted {
        background-color: $concrete !important;
    }
}
