/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _include includes all the core block type {less} files
 *
 */

@import 'newsletter';
@import 'accordion';
@import 'tabs';
@import 'map';
@import 'search';
@import 'code';
