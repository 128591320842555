.products-menu-darkbox {
  background-color: $black;
  height: 100%;
  left: 0;
  opacity: 0.25;
  position: absolute;
  top: 80px; //set top same as height from fixed header
  width: 100%;
  z-index: 12;
}
