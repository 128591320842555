//.modes {
//  float: right;
//}

ul.toolbar-tools {
    list-style-type: none;
    float: right;
    margin: 7px 0;

  @include media('<=phone') {
    width: 100%;
    float: left;
    padding-left: 0;
  }

  li {
    float: left;
    display: block;
    margin: 7px 0;

    @include media('<=phone') {
      width: 100%;
      float: none;
      .sorter,
      .modes {
        float: none;
      }
    }
  }
}

a.add-to-compare {
  color: rgba(48, 48, 48, 0.5);
  background-color: #fff;
  line-height: 50px;
  padding: 0 20px;
  margin-right: 10px;
  display: block;
  border: 1px solid #dddddd;
  height: 50px;
  border-radius: 4px;

  @include media('<=phone') {
    display: none;
  }

  .toolbar-bottom & {
    display: none;
  }
}

.modes,
.limiter {
  margin-right: 10px;
  @include media('<=phone') {
    margin-right: 0px;
  }
}

p.toolbar-amount {
  @extend %h5;
  float: left;
  left: 0;
  line-height: 50px;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @include media('<=tablet') {
    font-size: 12px;
  }
  @include media('<=phone') {
    display: none;
  }
}

.sorter,
.limiter {
  padding-top: 0;
}

.toolbar-products {
  padding: 0;
  text-align: left;
  position: relative;
  margin-bottom: 0;
  @include media('<=phone') {
    margin-bottom: 0;
  }

  select {
    width: 200px; //select2 copies width to style element!
    @include media('<=tablet') {
      width: 165px;
    }
  }

  span.select2 {
    @include media('<=tablet') {
      width: 165px !important;
    }
    @include media('<=phone') {
      width: 90% !important;
    }
  }
}
.catalog-category-view {
  .sorter-action{
    float: right;
  }
  .block.filter .filter-title{
    @include media('<=phone'){
       margin-bottom: 0;
    }
    strong{
      @include media('<=phone'){
        width: 90%;
      }
    }
  }
}

option.mode-grid {
  background: url(http://localhost:8080/fonts/check.svg) no-repeat center;
}
