.amshopby-slider-display {
  margin-top: 10px;
  color: rgba(51, 51, 51, 0.65);
  font-size: 16px;
  @include fontWeightLight();
}

.ui-slider.ui-slider-horizontal .ui-slider-handle {
  background: $christi;
  top: 0;
}

.ui-slider-horizontal {
  background: $desert-storm;
}

.ui-slider-range.ui-widget-header.ui-corner-all {
  background: $saltpan;
  height: 10px;
  position: relative;
}
