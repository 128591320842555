/**
* BlueFoot
 *
 * Version 1.0
 * @author Chloe Langford <chloe@gene.co.uk>
 *
 * _app contains general {less} for the apps
 *
 */

.bluefoot-app {
  .col2-right-layout .col-right {
    .block-title {
      border: 0;
    }
  }
}

.bluefoot-content-view-latest {
  .bluefoot-content-latest-item {
    padding: 9px;
  }
  .bluefoot-published-date {
    font-size: $bf-font-size--small;
    font-weight: 600;
    margin: 5px 0 0;
  }
  .bluefoot-excerpt {
    margin: 5px 0 0;
  }
  .bluefoot-latest-title {
    margin: 0;
  }
}

@include media("<=phone") {
  .bluefoot-app {
    .col2-right-layout .col-right {
      float: left;
      width: 100%;
    }
  }
}
