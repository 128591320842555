.header__usps--wrapper {
    position: relative;
    z-index: 2;
    padding-bottom: 10px;
    background: $white;

    strong {
        @include fontWeightBold();
    }

    @include media('<=tablet') {
        padding-bottom: 10px;
    }

    @include media('<=tablet') {
        display: none;
    }

    @include media('>tablet') {
        &:after {
            background: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0.1) 0%,
                    transparent 100%
            );
            bottom: 0;
            content: '';
            display: block;
            height: 10px;
            position: absolute;
            width: 100%;
        }
    }
}

.bluefoot-row .header_usps_mobile,
.header__usps--wrapper .header__usps {


    ul {
        @include media('>tablet') {
            display: flex;
            justify-content: space-between;
        }
        list-style-type: none;
        margin: 0;
        padding: 10px 0;
        width: 100%;

        @include media('<=tablet') {
            padding: 0;
        }

        li {
            @extend %usp;
            font-size: 14px;
            @include media('>tablet') {
                line-height: 26px;
            }
            @include media('<=desktop') {
                @include font-size($micro-size);
                color: rgba($cod-gray, 0.4);
                display: table;
                line-height: 26px;
                margin: 0 0 0 40px;
                padding: 0;
            }
            margin: 0 0 0 30px;
            padding-right: 30px;
            line-height: 50px;
            position: relative;

            &:before {
                @extend %check;
                content: '';
                display: block;
                height: 16px;
                left: -25px;
                position: absolute;
                top: 5px;
                width: 16px;
            }

            &.header__usps__kiyoh:before {
                background: url('../images/kiyoh.png') no-repeat center;

                @include media('>tablet') {
                    height: 24px;
                    width: 24px;
                    top: 14px;
                }
                @include media('>tablet') {
                    left: -34px;
                    top: 1px;
                }
            }

            a {
                color: rgba(48,48,48,.4);
            }
        }
    }
}

.header_usps_mobile {
    margin-top: -40px;
    margin-bottom: 0;
}

.column.main .header__usps--wrapper {
    border-top: 1px solid $gainsboro;

    .header__usps {
        @include container-size();
        @include media('<=tablet') {
            display: none;
        }
        width: 100%;
        padding-bottom: 0;

        ul {
            align-items: center;
            padding: 21px 0;
            @include media('<=tablet', ">phone") {
                padding: 20px 0 0 0;
                margin-bottom: -20px;
            }

            li {
                &:before {
                    top: 5px;
                }

                @include media('<=tablet', ">phone") {
                    &:before {
                        left: 0;
                        top: 5px;
                    }
                }
            }
        }
    }

    &:after {
        background: none;
        content: '';
        display: none;
    }

    @include media('>tablet') {
        ul {
            lost-column: 2/3;

            li {
                line-height: inherit;
                margin: 0;
                padding-left: 27px;

                &:before {
                    left: 0;
                    top: 21px;
                }

                &:last-child {
                    display: none;
                }
            }
        }
    }
}
