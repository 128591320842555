/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _video contains {less} for video iframes
 *
 */

.#{$prefix}-video {
  width: 100%;
  //min-height:500px;
  // Set default values
  iframe {
    height: 500px;
    width: 100%;
    max-width: 100% !important;
    border: none;
  }
}
