body.catalogsearch-result-index {
  &:not(.searchautocomplete__active) {
    .searchautocomplete__autocomplete {
      display: none;
    }
  }

  &.page-layout-2columns-left .column.main:last-child{
    margin-right: -1px;
  }
}


