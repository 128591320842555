.box-tocart .control .ui-spinner,
.ui-spinner {
  position: relative;
  div.mage-error,
  div.mage-error[generated] {
    @include media('<=phone') {
      display: none;
    }
  }
  input {
    border: 1px solid $gainsboro;
    border-radius: 40px;
    float: right;
    font-style: inherit;
    height: 40px;
    margin: 0;
    padding: 6px 5px 5px;
    text-align: center;
    width: 120px;
    line-height: initial;
  }
  .ui-button-text {
    display: none;
  }

  display: inline-block;
  float: right;
  position: relative;
  width: 140px;
  top: -9px;
  &-up,
  &-down {
    position: absolute;
    top: 5px;
    display: block;
    height: 30px;
    width: 30px;
    &:before {
      position: absolute;
      content: '';
      height: 30px;
      width: 30px;
      top: 0px;
    }
  }
  &-up {
    right: 9px;
    &:before {
      background: url('../svg/spinner/plus.svg') no-repeat center;
      cursor: n-resize;
      right: 0;
    }
  }
  &-down {
    left: 29px;
    &:before {
      background: url('../svg/spinner/min.svg') no-repeat center;
      cursor: s-resize;
      left: 0;
    }
  }
}
