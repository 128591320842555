.tooltip.amshopby-filter-tooltip {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 0;
  position: relative;
  border-radius: 8px;
  padding-right: 2px;
  text-align: center;
  line-height: 16px;
  background-color: $saltpan;
  @include media('<=tablet') {
    display: none;
  }
}
