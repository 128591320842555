button.pagebuilder-button-secondary,
a.pagebuilder-button-secondary,
button.pagebuilder-button-primary,
a.pagebuilder-button-primary {
    @include fontWeightBold();
    font-size: 18px;
    border-radius: 30px;
    border: 0;
    margin: 0;

    @include media('<=phone') {
        font-size: 15px !important;
    }

    &:hover {
        border: 0;
    }
}

button.pagebuilder-button-secondary,
a.pagebuilder-button-secondary {
    background-color: #fff;
    border-color: #fff;
    padding: 15px 30px 15px 30px;
    color: $primary;
    display: flex;
    align-items: center;

    &:after {
        content: "";
        background: url("../svg/arrow/chevron-green.svg") no-repeat center;
        width: 7px;
        height: 10px;
        margin-left: 3rem;
    }

    &:hover {
        color: $primary;
    }
}

button.pagebuilder-button-primary,
a.pagebuilder-button-primary {
    background: $primary;
    background-image: linear-gradient(-90deg, #81c30e 1%, rgba(129, 195, 14, 0) 100%);
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
    font-size: 17px;
    border: none;
    display: flex;
    align-items: center;

    &:after {
        content: '';
        background: url("../svg/arrow/chevron-white.svg") no-repeat center;
        background-color: $primary;
        background-size: 7px;
        border-radius: 20px;
        height: 30px;
        width: 30px;
        display: inline-block;
        margin-left: 2rem;
    }

    &:hover {
        background-image: none;
        border: 0;
        background-color: $primary;
    }
}
