body.catalog-category-view,
body.catalogsearch-result-index {

    .actions-primary {
        display: block;

        button.tocart {
            @extend %button-primary;
            border: none;
            width: 100%;
        }
    }

    .product--usps {
        ul {
            padding-left: 0;
            display: flex;
            flex-direction: column;
            min-height: 90px;

            li {
                display: inline-block;
                list-style-type: circle;
                position: relative;
                padding-left: 14px;
                margin-right: 28px;
                @include media('<=tablet') {
                    display: inline-block;
                }

                &:before {
                    content: '';
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    background-color: $christi;
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    top: 5px;
                    font-size: 14px;
                }
            }
        }
    }
}

.catalog-category-view {
    .products.wrapper {
        margin: 0;
    }
}

.catalog-category-view {
    .products.wrapper.grid {
        li.item.product.product-item {
            .product-item-info {
                width: 100%;
            }
        }
    }
}

body.catalog-category-view:not(.gplighting),
body.catalogsearch-result-index:not(.gplighting) {
    .products.wrapper.list {
        .actions-secondary span {
            display: none;
        }

        li.item.product.product-item {
            position: relative;
            background: $white;
            margin-bottom: 20px;
            padding: 0 30px 18px 30px;
            @include media("<=tablet") {
                padding: 0 30px 18px 0;
            }
            @include media("<=phone") {
                padding: 0 0 30px 0;
            }

            .actions-secondary {
                position: absolute;
                left: -35px;
                top: 50%;
                transform: translateY(-50%);

                .action.towishlist {
                    display: none;
                }

                .action.tocompare span {
                    display: none;
                }
            }

            .product-item-info {
                width: 100%;
                display: flex;
                height: 110px;

                @include media("<=phone") {
                    height: auto;
                }
            }

            .product-image-container {
                display: inline-block;
                width: 100px;

                @include media("<=phone") {
                    position: inherit;
                    top: inherit;
                    transform: inherit;
                }
                max-height: 100%;
                position: relative;
                top: 50%;
                transform: translateY(-48%);
                padding: 0;

                .product-image-container {
                    width: 100%;
                    left: 50%;
                    position: relative;
                    transform: translateX(-40%);

                    .product-image-wrapper {
                        height: auto;
                        overflow: visible;
                        padding: 20px 0 0;

                        .product-image-photo {
                            width: auto;
                            bottom: auto;
                            display: block;
                            height: 100%;
                            left: auto;
                            margin: auto;
                            max-width: 100%;
                            position: static;
                            right: auto;
                            top: auto;
                            max-height: 100px;
                        }
                    }
                }
            }

            .product-item-container {
                display: inline-block;
                position: relative;

                .product-item-name {
                    margin: 0 0 12px 0;

                    a.product-item-link {
                        @include fontWeightLight();
                        font-size: 18px;
                    }
                }
            }

            .list-reviews.mobile {
                display: none;
                @include media('<=tablet') {
                    display: block;
                }
            }

            .list-reviews {
                display: inline-block;
                position: relative;
                min-height: 1px;
                @include media('<=tablet') {
                    display: none;
                }

                .product-reviews-summary {
                    display: block;
                    margin-left: 3rem;

                    .rating-summary {
                        display: block;
                        margin: 0 0 12px 0;
                    }

                    .action.add {
                        display: none;
                    }

                    .reviews-actions {
                        line-height: inherit;
                    }
                }
            }

            .price-box.price-final_price {
                display: flex;
                flex-wrap: wrap;
                @include media("<=phone") {
                    position: inherit;
                    top: inherit;
                    transform: inherit;
                    text-align: right;
                }
                position: relative;
                margin: 0 0 0 auto;
                text-align: right;

                span.price-label {
                    display: none;
                }

                span.price {
                    @extend %price;
                    font-size: 20px;
                    @include fontWeightBold();
                    line-height: 19px;
                    display: block;
                }

                .price-wrapper[data-price-type='oldPrice'] {
                    span.price {
                        @include fontWeightLight();
                        opacity: 0.25;
                        color: $cod-gray;
                        margin-bottom: 10px;
                    }
                }

                span.old-price {
                    order: 1;
                }

                span.special-price {
                    order: 2;
                }
            }

            .product-item-description,
            .action.tocart.primary {
                display: none;
            }

            &:before {
                content: '';
                background: url('../svg/rectangle.svg');
                width: 2px;
                height: 2px;
                display: inline-block;
            }
        }
    }

    .products.wrapper.grid,
    .products-grid.grid {
        li.item.product.product-item,
        li.product-item {
            margin-bottom: 30px;
            padding-left: 0;

            @include media("<=phone") {
                margin-bottom: 12px;
            }

            .product-item {
                &-info {
                    display: block;
                    @include media('>=desktop') {
                        height: 435px;
                    }
                }

                &-photo {
                    display: block;
                }

                &-info {
                    position: relative;
                    @include media('>=desktop') {
                        overflow: hidden;
                        .product-item-details {
                            box-shadow: none;
                            bottom: 100px;
                            display: block;
                            position: absolute;
                            width: 100%;
                            z-index: 1;

                            .product-item-inner--wrapper {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .special-price {
                display: inline;
            }

            .old-price {
                float: left;
                color: $cod-gray;
                opacity: 0.25;
                margin-right: 10px;

                span.price {
                    color: $cod-gray;
                }
            }

            span.price-label {
                display: none;
            }

            .product-reviews-summary.short {
                @include media('>=desktop') {
                    position: absolute;
                }
                background: $white;
                bottom: 0px;
                padding: 0 15px;
                height: 50px;
                border-top: 1px solid $desert-storm;
                margin: 0;
                width: 100%;
                z-index: 4;

                .reviews-actions {
                    .action.view {
                        font-size: 14px;
                        color: $mineshaft;
                        opacity: 0.5;
                    }
                }
            }

            .actions-secondary {
                @include media('>=desktop') {
                    position: absolute;
                }
                @include media('<=phone') {
                    display: none;
                }
                display: block;
                background: $desert-storm;
                clear: both;
                width: 100%;
                margin: 0;
                padding: 15px 20px;
                bottom: 0;
                z-index: 4;

                span {
                    color: rgba($mineshaft, 0.5);
                    font-size: 16px;
                }

                .action.towishlist {
                    display: none;
                }
            }

            .product-image-container {
                position: relative;
                height: 270px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .product-item-container {
                position: absolute;
                bottom: 80px;
                width: 100%;
                transition: all 0.3s ease-out;
                transition-delay: 0.2s;

                @include media('<=desktop') {
                    position: relative;
                    bottom: 0;
                }
            }

            .product.details {
                background: $white;
                bottom: 0px;
                padding: 20px 20px 0px 20px;
                z-index: 4;
                border-top: 1px solid white;

                &:before {
                    content: '';
                    top: -10px;
                    left: 0;
                    height: 10px;
                    width: 100%;
                    position: absolute;
                    opacity: 0;
                    background-image: linear-gradient(
                            -180deg,
                            rgba($black, 0) 0%,
                            $black 100%
                    );
                    transition: opacity 0.3s ease-out;
                    transition-delay: 0.15s;
                }

                .price-box {
                    opacity: 1;
                    height: 24px;
                    width: 100%;
                    float: left;
                    position: absolute;
                    transition: opacity 0.4s ease-out, height 0.4s ease-out;
                    transition-delay: 0.3s;

                    @include media('<=desktop') {
                        position: relative;
                        margin-bottom: 10px;
                    }
                }
            }

            .product-item-actions {
                display: block;
                margin: 0px 0px 10px 0px;
            }

            .product--hover {
                display: block;
                float: left;
                width: 100%;
                position: relative;

                @include media('>=desktop') {
                    max-height: 0;
                }
                transition: max-height 0.25s ease-out;
                transition-delay: 0.15s;

                .product--usps {
                    float: left;
                    margin-top: 1px;
                    margin-bottom: 14px;

                    ul {
                        list-style-type: none;
                        margin: 0;
                        padding: 0;

                        li {
                            color: rgba($mineshaft, 0.65);
                            font-size: 14px;
                            line-height: 18px;
                            float: left;
                            width: 100%;
                            position: relative;
                            padding-left: 16px;
                            margin: 4px 0px;

                            &:before {
                                background-color: $christi;
                                border-radius: 100%;
                                content: '';
                                display: inline-block;
                                height: 6px;
                                margin-right: 6px;
                                position: absolute;
                                top: 5px;
                                left: 0;
                                width: 6px;
                            }
                        }
                    }
                }

                .product--usps,
                .product-item-inner {
                    @include media('>=desktop') {
                        opacity: 0;
                        transition: opacity 0.3s ease-out;
                        transition-delay: 0.3s;
                    }
                }
            }

            &:hover {
                .product-item-container {
                    bottom: 50px;
                    transition: all 0.3s ease-out;
                    transition-delay: 0s;

                    @include media('<=desktop') {
                        bottom: 0;
                    }
                }

                .product--hover {
                    max-height: 300px;
                    transition: max-height 0.25s ease-in;
                    transition-delay: 0s;

                    .product--usps,
                    .product-item-inner {
                        opacity: 1;
                        transition: opacity 0.3s ease-in;
                        transition-delay: 0.3s;
                    }
                }

                @include media('>=desktop') {
                    .product.details {
                        border-color: $desert-storm;
                        position: absolute;
                        width: 100%;
                        padding-bottom: 0px;

                        &:before {
                            content: '';
                            top: -10px;
                            left: 0;
                            height: 10px;
                            width: 100%;
                            position: absolute;
                            opacity: 0.05;
                            background-image: linear-gradient(
                                    -180deg,
                                    rgba($black, 0) 0%,
                                    $black 100%
                            );
                            transition: opacity 0.3s ease-in;
                        }

                        .price-box {
                            opacity: 0;
                            transition: opacity 0.15s ease-in, height 0.15s ease-in;
                            transition-delay: 0s;
                        }
                    }
                }
            }
        }
    }
}

.product-item-inner--wrapper {
    .swatch-opt-1 {
        @include media('<=tablet') {
            display: none;
        }
    }
}

.products-grid-search .item.product.product-item .product-item-info {
    height: 405px !important;

    .product-item-details {
        bottom: 0 !important;

        .product-item-actions {
            margin-top: 20px !important;

            .actions-secondary {
                display: none !important;
            }
        }
    }
}

.product--hover button.stock {
    border: none;
    border-radius: 0;
    cursor: default;
    line-height: 50px;
    padding: 0 10px;
    margin: 0;
    height: 50px;
    font-weight: normal;

    &.outofstock {
        background-color: $error-bg;
        color: $error-content;
    }
}

//IE fix for lost-grid
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    body.catalog-category-view .products-grid li.item.product.product-item:nth-child(3n) {
        margin-right: -1px;
    }
}

@supports (-ms-ime-align: auto) {
    body.catalog-category-view .products-grid li.item.product.product-item:nth-child(3n) {
        margin-right: -1px;
    }
}


body[class*=gpl] {

    .products-grid .product-item-actions {
        margin: 10px 0 0;
    }

    .product-reviews-summary {
        display: block;
        float: none;
        padding: 10px 20px;
        border-top: 1px solid #f8f8f8;
    }
}

.column.main {
    .product-items {
        margin: 0;
    }
}
