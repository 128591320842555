//pass breakpoints to theme-default blocks/_layout.scss
@import "variables/media-breakpoints";
@import "mixins/font-smoothing";

$font-family__sans-serif: "HelveticaNeueLTStd-Roman";
$font-family__sans-serif--light: "HelveticaNeueLTStd-Lt";
$font-family__sans-serif--bold: "HelveticaNeueLTStd-Bd";
$font-family__serif: "HelveticaNeueLTStd-It";
$font-family__serif--light: "HelveticaNeueLTStd-Lt";

$font-family__base: $font-family__sans-serif;

$checkout-sidebar__columns: 4 !default;

$color-blue1: rgba(51, 51, 51, .5);
$color-blue2: darken($color-blue1, 20);
$color-sky-blue1: $color-blue1;

$color-orange-red1: $color-blue1;
$color-orange-red2: lighten($color-blue1, 20);
$color-orange-red3: $color-orange-red1;
$color-orange-red4: $color-orange-red2;

//bluefoot vars
$bf-font-size--small: 16px;
$bf-margin: 30px;
$bf-border_color: #e4e4e4;
