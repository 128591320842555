.block.carousel {
    .block-title.title {
        @include media('<=phone') {
            text-align: center;
        }

        #block-carousel-heading {
            @extend %h3;
            @extend %h3--primary;
            margin: 0 0 40px;
            display: block;
            font-weight: 200;

            @include media('<=phone') {
                margin-bottom: 30px;
            }
        }
    }
}

.carousel-row,
.bestsellers-row {
    background-color: $desert-storm;
    border-top: 1px solid $border-top-gray;
    margin-top: 20px;
    overflow: hidden;
    padding: 60px 0 30px;

    @include media('<=phone') {
        padding-top: 30px;
    }

    .bluefoot-wrapper {
        margin-top: 0;
        padding: 0;
    }
}

.bestsellers-row {
    margin-top: 40px;
}

.products-carousel {
    .product-items {
        .product-item {
            @include media('>phone') {
                lost-column: 1/4 4;
                background: $white;
                box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);
                border-radius: 3px;
            }

            @include media('>phone', '<=tablet') {
                min-height: 320px;
            }

            @include media('<=tablet') {
                background: transparent;
                padding: 0 10px;
                transition: height 0.3s ease;
                &.swiper-slide-active {
                    padding: 0 10px 0 10px;
                    transition: all 0.3s ease;
                }
                .product-item-info {
                    background: $white;

                    .product-image-container {
                        padding: 0 10px;
                    }
                }
            }
            @include media('<=tablet', ">phone") {
                background: $white;
            }

            &.swiper-slide-active
            .product-item-info
            .product-image-container
            .product-image-wrapper {
                height: 100%;
                width: 100%;
            }

            .product-item-info {
                .product-image-container {
                    width: 100%;

                    .product-image-wrapper {
                        height: auto;
                        overflow: visible;
                        padding: 20px 0 0;
                        @include media('<=phone') {
                            height: 80%;
                            width: 80%;
                        }

                        .product-image-photo {
                            width: 200px;
                            bottom: auto;
                            display: block;
                            height: auto;
                            left: auto;
                            margin: auto;
                            max-width: 100%;
                            position: static;
                            right: auto;
                            top: auto;
                        }
                    }
                }

                .product-item-details {
                    padding: 20px;

                    .product-item-name {
                        @extend %product-title;
                    }

                    .price {
                        @extend %price;
                        font-size: 20px;
                        line-height: 24px;
                    }

                    .product-item-actions {
                        display: none;
                    }
                }
            }

            .price-box {
                margin: 0;

                .price-label {
                    display: none;
                }

                .old-price {
                    display: none;
                }
            }
        }
    }
}
