.nav-sections-item-title {
  display: none;
}

@include media('<=tablet') {
  .section-item-content.nav-sections-item-content {
    li.home,
    li.all-category {
      display: none;
    }
  }
  .nav-open {
    .nav-toggle:after {
      background: transparent;
    }
    .nav-sections {
      width: 100%;
    }
  }

  .navigation li.level0,
  .navigation li.level0:last-child {
    border: none;
  }

  .nav-sections {
    background-color: $cod-gray;
    border-bottom: none;
    .navigation {
      background-color: transparent;
      height: 100%;
      li.level0 {
        margin: 0;
        > .level-top {
          background-color: $rangoon-green;
          border-radius: 3px;
          color: rgba($white, 0.5);
          font-size: 16px;
          @include fontWeightLight();
          line-height: 50px;
          margin: 0 0 10px 0;
          padding: 0 20px;
          text-transform: none;
          width: 100%;
          &:after {
            background: url('~theme-assets/svg/arrow/chevron-white.svg')
              no-repeat center;
            height: 10px;
            right: 20px;
            top: 20px;
            width: 10px;
          }
          &.ui-state-active:after{
            transform: rotate(90deg);
          }
        }

        &.active > a:not(.ui-state-active) span:not(.ui-menu-icon),
        &.has-active > a:not(.ui-state-active) span:not(.ui-menu-icon) {
          margin-left: 0;
        }

        &.active > .level-top,
        &.has-active > .level-top {
          color: rgba($white, 0.5);
          &:after {
            top: 20px;
          }
        }

        &:hover {
          > .level-top:after {
            background: url('~theme-assets/svg/arrow/chevron-white.svg')
              no-repeat center;
          }
          > a {
            color: rgba($white, 0.5);
          }
        }
        ul.level0 {
          background-color: $rangoon-green;
          margin: -10px 0 10px 0;
          > li a {
            border-top: 1px solid $cod-gray;
            color: $white;
            @include fontWeightBold();
            font-size: 14px;
            line-height: 50px;
            margin: 0 20px;
            padding: 0 0;
          }
          .level1.parent:after {
            background: url('~theme-assets/svg/arrow/chevron-white.svg')
              no-repeat center;
            height: 10px;
            right: 20px;
            top: 18px;
            transform: rotate(90deg);
            width: 10px;
          }
          ul.level1 {
            background-color: $rangoon-green;
            margin: 0;
            padding: 0 20px;
            > li a {
              border: none;
              color: rgba($white, 0.65);
              font-size: 14px;
              font-weight: normal;
              line-height: 32px;
              margin: -10px 0 10px 0;
              padding: 0;
            }
            .level1.parent:after {
              background: url('~theme-assets/svg/arrow/chevron-white.svg')
                no-repeat center;
              height: 10px;
              right: 20px;
              top: 18px;
              transform: rotate(90deg);
              width: 10px;
            }
          }
        }
      }
    }
    &-item-content {
      float: inherit;
      margin-left: 0;
      padding: 20px;
    }
  }

  .nav-toggle {
    border-radius: 20px;
    background-color: $white;
    cursor: pointer;
    display: block;
    height: 40px;
    left: inherit;
    padding-top: 11px;
    position: absolute;
    right: 10px;
    text-align: center;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    top: 10px;
    width: 64px;
    &:before {
      content: '';
      display: none;
    }

    span {
      background: $christi;
      border-radius: 9px;
      display: block;
      height: 3px;
      opacity: 1;
      position: relative;
      right: -30%;
      width: 40%;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        @include visible-for-screenreader();
      }

      &:nth-child(2) {
        top: 0;
      }

      &:nth-child(3) {
        top: 6px;
      }

      &:nth-child(4) {
        top: 12px;
      }
    }
    &.open span {
      &:nth-child(2) {
        top: 9px;
        transform: rotate(135deg);
      }

      &:nth-child(3) {
        opacity: 0;
        right: -60px;
      }

      &:nth-child(4) {
        top: 6px;
        transform: rotate(-135deg);
      }
    }
  }
}

.navigation .submenu:not(:first-child) .level1.active > a {
  padding-left: 0;
  border: none;
  color: $christi;

  @media screen and (min-width: 768px) {
    padding: 8px 20px;
  }
}

.products-menu {
  display: none;
}
