/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _include includes all the core block type {less} files
 *
 */

/*-- Basic --*/
@import 'basic/import';

/*-- Media --*/
@import 'media/import';

/*-- Commerce --*/
@import 'ecommerce/import';

/*-- App --*/
@import 'app/import';

/*-- Other --*/
@import 'special/import';
