%button-large-init {
  border-radius: 32px;
  display: inline-block;
  @include fontWeightBold();
  font-size: 20px;
  padding: 0 60px 0 30px;
  position: relative;
}

%button-regular-init {
  display: inline-block;
  @include fontWeightBold();
  font-size: 15px;
  font-weight: 300;
  position: relative;
}

%button-small-init {
  display: inline-block;
  @include fontWeightBold();
  font-size: 13px;
  position: relative;
}

%button-more-after {
  background-size: 7px;
  border-radius: 20px;
  content: '';
  height: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
}

%button-primary {
  @extend %button-large-init;
  background: $christi;
  background-image: linear-gradient(-90deg, $lima 1%, rgba(129, 195, 14, 0) 100%);
  border: none;
  color: $white;
  font-size: 17px;
  line-height: 50px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  text-align: left;
  &:after {
    @extend %button-more-after;
    @extend %chevron-white-right;
    background-color: $fern;
  }
  &--large {
    line-height: 60px;
    &:after {
      height: 40px;
      width: 40px;
    }
  }
  &:hover {
    background-image: none;
  }
}

%button-secondary {
  @extend %button-large-init;
  background: $ucla-gold;
  background-image: linear-gradient(-90deg, $gold 3%, rgba(255, 181, 0, 0) 98%);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.25);
  color: $white;
  line-height: 50px;
  text-align: left;
  &:after {
    @extend %button-more-after;
    @extend %chevron-white-right;
    background-color: $ucla-gold;
  }
}

%button-white {
  @extend %button-regular-init;
  border-radius: 15px;
  color: $christi;
  display: inline-block;
  line-height: 40px;
  padding: 0 30px;
  &--small {
    border-radius: 20px;
    color: $christi;
    line-height: 36px;
    text-align: center;
  }
  &--more {
    padding: 0 60px 0 30px;
    &:after {
      @extend %button-more-after;
      @extend %chevron-green-right;
      height: 40px;
      right: 5px;
      top: 0;
      width: 40px;
    }
  }
}

%button-ghost {
  &-default {
    @extend %button-regular-init;
    background: $white;
    border: 2px solid rgba($cod-gray, 0.35);
    border-radius: 15px;
    color: rgba($cod-gray, 0.35);
    display: inline-block;
    line-height: 26px;
    padding: 0 30px;
    transition: background 0.3s ease-out, color 0.3s;
    &--download {
      padding: 0 30px 0 15px;
      &:after {
        @extend %download-gray;
        background-size: 10px;
        content: '';
        height: 30px;
        position: absolute;
        right: 0;
        top: -1px;
        width: 30px;
      }
    }
    &--small {
      border-radius: 20px;
      color: rgba($cod-gray, 0.35);
      line-height: 40px;
      text-align: center;
    }
    &:hover {
      background-color: rgba($cod-gray, 0.35);
      color: $white;
      text-decoration: none;
    }
  }
  &-primary {
    @extend %button-large-init;
    border: 2px solid $christi;
    color: $christi;
    line-height: 46px;
    font-size: 18px;
    padding: 0 30px;
    transition: background 0.3s ease-out, color 0.3s;

    &:hover {
      background-color: $christi;
      color: $white;
      text-decoration: none;
    }

    &--more {
      padding: 0px 35px 0px 30px;
      &:after {
        @extend %button-more-after;
        @extend %chevron-green-right;
        height: 40px;
        right: 5px;
        top: 3px;
        width: 40px;
      }
    }
    &--small {
      font-size: 15px;
      line-height: 36px;
      padding: 0 20px;
    }
  }
}
