/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _view contains {less} for the blog view
 *
 */

.bluefoot-content-view {
  img,
  iframe {
    max-width: 100%;
  }
  .bluefoot-content-view-page-title {
    h1 {
      border-bottom: none;
    }
  }
}

.bluefoot-content-view-share {
  min-height: 40px;
  margin-bottom: $bf-margin;
}

.bluefoot-content-featured-image {
  margin-bottom: $bf-margin;
  display: block;
}

.bluefoot-content-view-taxonomies {
  margin-top: $bf-margin;
  padding-top: $bf-margin;
  border-top: 1px solid $bf-border-color;
  .bluefoot-content-view-taxonomy-list {
    list-style: none;
    padding-left: 0;
    li {
      display: inline;
      margin-left: 0;
      &.bluefoot-list-divider {
        margin: 0 $bf-margin/2;
        opacity: 0.5;
      }
    }
  }
}

/*-- Comments --*/

.bluefoot-content-view-comments {
  padding-top: $bf-margin;
  border-top: 1px solid $bf-border-color;
}
