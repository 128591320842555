.product-reviews-summary {
  @include media(">phone") {
    float: left;
  }
  margin: 0;
  .rating-summary {
    left: 0;
    width: 140px;
    .rating-result {
      margin-left: 0;
      width: 121px;
      &:before {
        background: url("../svg/stars/star-blank.svg") no-repeat left;
        content: '';
        height: 20px;
        width: 100%;
      }
      > span:before {
        background: url("../svg/stars/star-full.svg") no-repeat left;
        content: '';
        height: 20px;
      }
    }
  }
}

.reviews-actions a {
  @extend %p--light;
}

.page-title-wrapper .product-reviews-summary:not(.empty) .action.add {
  display: none;
}

.modals {
  margin-top: -20px;
  @include media(">=phone") {
    float: left;
  }
  @include media('<=phone'){
    margin-top: -10px;
  }
  p {
    @extend %p--light;
    @include media(">=tablet") {
      border-left: 1px solid $gainsboro;
      margin: 0 0 0 8px;
      padding: 0 0 0 13px;
    }
    line-height: 28px;

    @include media('<=tablet'){
      line-height: 15px;
    }
  }
}
