footer.page-footer {
  .sos-box-clone section.sos-box {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    padding-left: 15px;
    position: relative;
    width: calc(100% - 30px);
    h5 {
      margin-top: 0;
    }
    p.chat {
      margin-bottom: 0;
    }
  }
  .footer.content {
    border-top: none;
    margin-top: 0;
    padding: 0;
    .block {
      float: none;
      margin: 0;
    }
    .mobile-top-box {
      display: none;
      padding: 0 15px;
      @include media('<=phone') {
        display: block;
      }
      h5 {
        @extend %button-text--inversed--small;
      }
      p {
        @include font-size($p-size);
        color: rgba($white, 0.5);
        @include fontWeightLight();
      }
      ul {
        @include clearfix;
        margin: 20px 0;
        li {
          float: left;
          a {
            margin-right: 10px;

            display: block;
            height: 32px;
            width: 32px;
            &:before {
              background-size: 32px;
              content: '';
              display: block;
              height: 32px;
              width: 32px;
            }
          }
          &:nth-child(1) a:before {
            @extend %facebook;
          }
        }
      }
    }

    .sos-box {
      bottom: 0;
      position: relative;
      @include media('<tablet', '>=phone') {
        bottom: 0;
        margin-top: 30px;
      }
      @include media('<phone') {
        bottom: inherit;
        margin-top: 15px;
      }
      @include media('<desktop', '>=tablet') {
        p {
          a:before {
            margin-right: 10px;
          }
          span {
            margin-left: 27px;
          }
        }
      }

      body[class*="catalog-product-view"] & {
        display: none;
      }
    }
    .footer-columns--wrapper {
      padding-top: 25px;
      @include media('>=tablet') {
        padding-bottom: 0;
      }
      @include media('<phone') {
        padding-top: 0;
        padding-bottom: 10px;
      }

      .column-container {
        @include clearfix();
      }
    }

    .footer-columns {
      background: $cod-gray;
      border-top: 5px solid $christi;
      padding: 0 15px;
      position: relative;
      z-index: 10;
      @include media('<=phone') {
        padding: 20px 0 80px 0;
        position: absolute;
        width: 100%;
      }
    }

    .footer-column {
      &:not(.switcher-store) {
        padding: 0;
        position: relative;
        &:nth-child(4) {
          padding: 0;
          .sos-box {
            @include media('<=phone') {
              display: none;
            }
          }
        }
        @include media('>tablet') {
            width: calc(100% * 2/8 - 30px);

            &:nth-child(n) {
                float: left;
                margin-right: 40px;
            }

            &:nth-child(4n) {
                margin-right: 0;
            }
        }
        @include media('>phone', '<=tablet') {
            width: calc(100% * 2/4 - 20px);
            padding-bottom: 0;
            padding-top: 0;

            &:nth-child(2n+1) {
                clear: both;
            }

            &:nth-child(n) {
                float: left;
                margin-right: 20px;
            }

            &:nth-child(4n) {
                margin-right: 0;
            }
        }
        @include media('<=phone') {
          padding: 0;
          &:nth-child(4) {
            padding: 0;
          }
        }
      }

      ul {
        @include media('<=phone') {
          background-color: rgba($black, 0.4);
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          margin: 0 0 5px 0;
          padding: 0 20px 15px 20px;
        }
        li {
          &:nth-child(1) {
            border-top: 1px solid $cod-gray;
            padding-top: 10px;
          }
          a {
            color: rgba($white, 0.5);
            font-size: 16px;
            font-family: $font-family__serif;
            line-height: 35px;

            @include media('<=phone') {
              color: $white;
              font-size: 14px;
              line-height: 32px;
            }
          }
        }
        &.social {
          @include clearfix;
          @include media('<=phone') {
            display: none;
          }
          margin: 20px 0;
          li {
            border-top: none;

            &:nth-child(1) {
              padding-top: 0;
            }
            float: left;
            a {
              margin-right: 10px;
              display: block;
              height: 32px;
              width: 32px;
              &:before {
                background-size: 32px;
                content: '';
                display: block;
                height: 32px;
                width: 32px;
              }
            }
            &:nth-child(1) a:before {
              @extend %facebook;
            }
            &:nth-child(1) a:hover::before {
              @extend %facebook-white;
            }
          }
        }
      }
    }
  }
}

footer .switcher-label,
.footer-columns h4 {
  color: $white;
  margin: 30px 0 10px 0;
  font-size: 20px;
  @include fontWeightBold();
  line-height: 24px;
  @include media('<=phone') {
    background-color: rgba($black, 0.4);
    color: rgba($white, 0.5);
    border-radius: 3px;
    cursor: pointer;
    @include fontWeightLight();
    font-size: 16px;
    line-height: 50px;
    margin: 0 0 5px 0;
    padding: 0 20px;
    position: relative;
    &.open {
      margin: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:after {
      background-size: 7px;
      content: '';
      display: block;
      height: 10px;
      position: absolute;
      top: 20px;
      transform: rotate(90deg);
      right: 20px;
      width: 7px;
    }
  }
  &:after {
    @extend %chevron-white-right;
  }
}

.footer__usps .bluefoot-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;

  @include media('>=large') {
    &:before,
    &:after {
      content: '';
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 0;
  }

  figure {
    .glow {
      display: none;
    }
    @include media('<=phone') {
      margin: 0 7px 30px;
      &:nth-child(1) {
        margin-left: 15px;
      }
      &:nth-child(6) {
        margin-right: 15px;
      }
    }
  }
}


.block.newsletter{
  width: 100%;
  margin-top: 20px;

  .field .control:before{
    display: none;
  }

  #newsletter{
    background: white;
  }

  .action.subscribe{
    height: 50px;
    border-radius: 4px;
    border: 2px solid $christi;
    background: transparent;
    color: $christi;
    line-height: 36px;
    &:hover{
      color: $gold;
      background: $christi;
    }
    @include media('<=phone'){
      margin-left: 10px;
    }
  }
}

.subscription{
  color: $white;
  font-weight: bold;
  font-size: 16px;
}

a.store-swtich{
  color: $white;
  display: flex;
  align-items: center;
  cursor: pointer;

  img{
    flex-shrink: 0;
    margin-right: 5px;
  }

  span{
    line-height: 16px;
    margin-top: 5px;
    font-size: 16px;
  }


}
