$font-family__sans-serif: 'HelveticaNeueLTStd-Roman';
$font-family__sans-serif--light: 'HelveticaNeueLTStd-Lt';
$font-family__sans-serif--bold: 'HelveticaNeueLTStd-Bd';
$font-family__sans-serif--italic: 'HelveticaNeueLTStd-It';
$font-family__serif: 'HelveticaNeueLTStd-Roman';

$h1-size: (
   (query: ('>=large'), sizes: (44px, 60px)),
   (query: ('>=desktop', '<=large'), sizes: (44px, 60px)),
   (query: ('>=tablet', '<=desktop'), sizes: (36px, 54px)),
   (query: ('>=phone', '<=tablet'), sizes: (32px, 48px)),
   (query: ('<=phone'), sizes: (28px, 42px))
);

$h2-size: (
   (query: ('>=large'), sizes: (28px, 42px)),
   (query: ('>=desktop', '<=large'), sizes: (28px, 42px)),
   (query: ('>=tablet', '<=desktop'), sizes: (26px, 39px)),
   (query: ('>=phone', '<=tablet'), sizes: (24px, 36px)),
   (query: ('<=phone'), sizes: (22px, 33px))
);

$h3-size: (
   (query: ('>=large'), sizes: (24px, 36px)),
   (query: ('>=desktop', '<=large'), sizes: (24px, 36px)),
   (query: ('>=tablet', '<=desktop'), sizes: (22px, 33px)),
   (query: ('>=phone', '<=tablet'), sizes: (22px, 33px)),
   (query: ('<=phone'), sizes: (20px, 30px))
);

$h4-size: (
   (query: ('>=large'), sizes: (20px, 30px)),
   (query: ('>=desktop', '<=large'), sizes: (20px, 30px)),
   (query: ('>=tablet', '<=desktop'), sizes: (20px, 30px)),
   (query: ('>=phone', '<=tablet'), sizes: (18px, 27px)),
   (query: ('<=phone'), sizes: (16px, 24px))
);

$h5-size: (
   (query: ('>=large'), sizes: (18px, 27px)),
   (query: ('>=desktop', '<=large'), sizes: (16px, 24px)),
   (query: ('>=tablet', '<=desktop'), sizes: (16px, 24px)),
   (query: ('>=phone', '<=tablet'), sizes: (16px, 24px)),
   (query: ('<=phone'), sizes: (16px, 24px))
);

$h6-size: (
   (query: ('>=large'), sizes: (16px, 24px)),
   (query: ('>=desktop', '<=large'), sizes: (16px, 24px)),
   (query: ('>=tablet', '<=desktop'), sizes: (16px, 24px)),
   (query: ('>=phone', '<=tablet'), sizes: (16px, 24px)),
   (query: ('<=phone'), sizes: (16px, 24px))
);

$p-size: (
   (query: ('>=large'), sizes: (16px, 24px)),
   (query: ('>=desktop', '<=large'), sizes: (14px, 24px)),
   (query: ('>=tablet', '<=desktop'), sizes: (14px, 24px)),
   (query: ('>=phone', '<=tablet'), sizes: (14px, 24px)),
   (query: ('<=phone'), sizes: (14px, 24px))
);

$light-65-size: (
   (query: ('>=large'), sizes: (16px, 25px)),
   (query: ('>=desktop', '<=large'), sizes: (14px, 25px)),
   (query: ('>=tablet', '<=desktop'), sizes: (14px, 25px)),
   (query: ('>=phone', '<=tablet'), sizes: (14px, 25px)),
   (query: ('<=phone'), sizes: (14px, 25px))
);

$super-size: (
   (query: ('>=large'), sizes: (52px, 78px)),
   (query: ('>=desktop', '<=large'), sizes: (52px, 78px)),
   (query: ('>=tablet', '<=desktop'), sizes: (48px, 72px)),
   (query: ('>=phone', '<=tablet'), sizes: (46px, 69px)),
   (query: ('<=phone'), sizes: (30px, 45px))
);

$middle-size: (
   (query: ('>=large'), sizes: (22px, 33px)),
   (query: ('>=desktop', '<=large'), sizes: (22px, 33px)),
   (query: ('>=tablet', '<=desktop'), sizes: (20px, 30px)),
   (query: ('>=phone', '<=tablet'), sizes: (20px, 30px)),
   (query: ('<=phone'), sizes: (18px, 27px))
);

$custom-cms-page-size: (
   (query: ('>=large'), sizes: (22px, 33px)),
   (query: ('>=desktop', '<=large'), sizes: (22px, 33px)),
   (query: ('>=tablet', '<=desktop'), sizes: (20px, 30px)),
   (query: ('>=phone', '<=tablet'), sizes: (20px, 30px)),
   (query: ('<=phone'), sizes: (16px, 24px))
);

$small-size: (
   (query: ('>=large'), sizes: (17px, 26px)),
   (query: ('>=desktop', '<=large'), sizes: (17px, 26px)),
   (query: ('>=tablet', '<=desktop'), sizes: (17px, 26px)),
   (query: ('>=phone', '<=tablet'), sizes: (17px, 26px)),
   (query: ('<=phone'), sizes: (17px, 26px))
);

$mini-size: (
   (query: ('>=large'), sizes: (15px, 22px)),
   (query: ('>=desktop', '<=large'), sizes: (15px, 22px)),
   (query: ('>=tablet', '<=desktop'), sizes: (15px, 22px)),
   (query: ('>=phone', '<=tablet'), sizes: (15px, 22px)),
   (query: ('<=phone'), sizes: (14px, 21px))
);

$micro-size: (
   (query: ('>=large'), sizes: (14px, 28px)),
   (query: ('>=desktop', '<=large'), sizes: (14px, 28px)),
   (query: ('>=tablet', '<=desktop'), sizes: (14px, 28px)),
   (query: ('>=phone', '<=tablet'), sizes: (14px, 28px)),
   (query: ('<=phone'), sizes: (14px, 28px))
);

$nano-size: (
   (query: ('>=large'), sizes: (13px, 25px)),
   (query: ('>=desktop', '<=large'), sizes: (13px, 25px)),
   (query: ('>=tablet', '<=desktop'), sizes: (11px, 25px)),
   (query: ('>=phone', '<=tablet'), sizes: (11px, 25px)),
   (query: ('<=phone'), sizes: (11px, 25px))
);

$menu-size: (
   (query: ('>=large'), sizes: (18px, 70px)),
   (query: ('>=desktop', '<=large'), sizes: (18px, 70px)),
   (query: ('>=tablet', '<=desktop'), sizes: (14px, 70px)),
   (query: ('>=phone', '<=tablet'), sizes: (14px, 50px)),
   (query: ('<=phone'), sizes: (14px, 70px))
);

$price-size: (
   (query: ('>=large'), sizes: (40px, 48px)),
   (query: ('>=desktop', '<=large'), sizes: (40px, 48px)),
   (query: ('>=tablet', '<=desktop'), sizes: (32px, 40px)),
   (query: ('>=phone', '<=tablet'), sizes: (32px, 40px)),
   (query: ('<=phone'), sizes: (24px, 32px))
);
