[data-content-type="row"] {
    &[data-appearance="full-width"] {
        [data-element="inner"] {
            @include container-size();

            @include media('<=phone') {
                padding: 0 15px;
            }
        }
    }

    &[data-appearance="contained"] {
        @include media('<=phone') {
            padding: 1rem 0;
        }
    }

    &.row-products {
        .pagebuilder-column {
            @include media('<=phone') {
                text-align: center;
            }
        }

        [data-content-type="heading"] {
            @include media('<=phone') {
                margin-bottom: 0;
            }
        }

        [data-content-type="buttons"] {
            @include media('<=phone') {
                text-align: center !important;
            }
        }
    }
}
