[data-appearance="full-bleed"] {
    [data-content-type='banner'] {
        .pagebuilder-banner-wrapper {
            height: 500px;

            @include media('<=phone') {
                height: 180px !important;
                padding: 0 15px;
            }
        }

        [data-link-type='page'] {
            pointer-events: none;
        }

        .pagebuilder-overlay {
            @include container-size();
            padding: 0;
            height: inherit;
        }

        [data-element="content"] {
            h2 {
                color: $gold;
                @include fontWeightLight();
                font-size: 44px;
                display: inline-block;
                margin-bottom: 2.5rem;

                @include media('<=phone') {
                    font-size: 28px;
                    margin-bottom: 0;
                }
            }
        }

        [data-element="button"] {
            pointer-events: all;
        }
    }
}

[data-content-type="column"] {
    [data-content-type="banner"] {
        border-radius: 3px;
        overflow: hidden;

        @include media('>=phone', '<desktop') {
            height: 210px;
        }

        @include media('>=large') {
            height: 415px;
        }

        [data-element="overlay"] {
            align-items: flex-end;
            padding: 3rem;
            min-height: auto !important;
            height: inherit;

            @include media('<=phone') {
                padding: 1.5rem;
            }
        }

        [data-element="link"],
        .pagebuilder-banner-wrapper {
            height: inherit;
        }
    }
}
