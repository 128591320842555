.page-footer .switcher .label {
  width: auto;
  height: auto;
  clip: unset;
  display: block;
  padding-bottom: 10px;
  overflow: unset;
  position: relative;
  @include fontWeightBold();
}

#switcher-store {
  .switcher-option {
    color: rgba($white, 0.5);
    font-size: 15px;
    display: inline-block;
    margin: 0 10px 0 0;
    @include media('<=phone') {
      margin: 0 10px 0 0;
      width: 100%;
    }
  }
}

#switcher-language-nav {
  .switcher-options {
    color: rgba($white, 0.5);
    font-size: 15px;
    margin: 0 10px 0 0;
    @include media('<=phone') {
      margin: 0 10px 0 0;
    }
    :before {
      display: none;
    }
  }
}

.store-container {
    width: calc(100% * 3/4 - 10px);
    margin: 30px 0 0 0;

    &:nth-child(n) {
        float: left;
        margin-right: 40px;
        clear: none;
    }

    @include media('<=tablet') {
        lost-column: 1/2 1;
    }
    @include media('<=phone') {
        margin: 0;
        lost-column: 1/1;
    }
}

.footer-columns--wrapper {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
