.account{
    .columns{
        margin-top: 60px;
    }

    &.page-layout-2columns-left .column.main{
        &:nth-child(1n) {
            float: right;
            margin-right: 0;
        }
        lost-column: 3/4 2;
    }

    &.page-layout-2columns-left .columns .sidebar-container{
        &:nth-child(1n) {
            margin-right: 0;
            margin-left: 0;
            @include media("<=tablet"){
                order: -1;
            }
        }

        @include media("<=tablet"){
            width: 100%;
        }

        width: 20%;
    }

    .account-nav {
        .item {
            a {
                color: rgba(48, 48, 48, 0.4);
            }
        }

        .item a:hover{
            background: $desert-storm;
        }

        .item.current a,
        .item.current strong{
            color: $christi;
        }
    }

    &.page-layout-2columns-left .sidebar-additional,
    &.page-layout-2columns-left .sidebar-main {
        width: 100%;
    }

    .column.main a.action:not(.back){
        color: $christi;
        padding: 0 20px 0 0;
    }

    .fieldset > .field:not(.choice) > .label {
        @extend %label;
        @extend %label--small;
        line-height: 50px;
        padding: 0;
        text-align: left;
    }
    .actions-toolbar .primary{
        .action.primary{
            @extend %button-primary;
            border: none;
            padding: 0 60px 0 30px;
        }
    }
    .actions-toolbar .secondary a{
        line-height:50px;
        margin-top: 0;
    }

    .mp-gdpr-delete-account {
        margin-top: 40px;
    }
}

.account .tocart.primary{
    white-space: nowrap;
}

.account-nav .item.current a, .account-nav .item.current strong{
    border-color: $christi;
}
