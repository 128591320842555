.action.login.primary,
.title-button button,
.action.primary.notify,
.action.create.primary,
.action.submit.primary,
.action.checkout.primary {
    @include fontWeightBold();
    background-color: #63a70a;
    background-image: linear-gradient(-90deg, #81c30e 1%, rgba(129, 195, 14, 0));
    color: #fff;
    font-size: 17px;
    line-height: 50px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .05);
    text-align: left;
    border: none;
    border-radius: 32px;
    display: inline-block;
    padding: 0 60px 0 30px;
    position: relative;

    &:after {
        background-color: #448a05;
        background-size: 7px;
        border-radius: 20px;
        content: "";
        height: 30px;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 30px;
        background-image: url('../svg/arrow/chevron-white.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
}

.video-play-container {
    background-color: rgba($white, 0.36);
    width: 71px;
    height: 71px;
    border-radius: 100%;
    position: relative;
    margin: 0px auto;
    padding: 3px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;

    .video-play {
        background-color: yellow;
        width: 65px;
        height: 65px;
        border-radius: 100%;
        text-align: center;
        display: inline-block;

        svg {
            fill: $white;
            width: 23px;
            height: 23px;
            position: relative;
            top: 21px;
            left: 3px;
        }
    }
}
