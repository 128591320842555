input[type="radio"] {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    .actions-secondary &.compare-select + span.label{
        &:before {
            top: 0;
            margin-right: 13px;
        }
        &:after {
            top: 0;
        }
    }

    & + label.label,
    & + span.label {
        position: relative;
        cursor: pointer;
        padding-left: 30px;
    }

    // Box.
    & + label.label:before,
    & + span.label:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        display: inline-block;
        width: 18px;
        height: 18px;
        background-color: $white;
        border-radius: 9px;
        border: 1px solid $gainsboro;
    }

    // Box hover
    &:hover + label.label:before,
    &:hover + span.label:before {
        background-color: $christi;
    }

    // Box focus
    &:focus + label.label:before,
    &:focus + span.label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label.label:before,
    &:checked + span.label:before {
        background-color: $white;
    }

    // Disabled state span.label.
    &:disabled + label.label,
    &:disabled + span.label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label.label:before,
    &:disabled + span.label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label.label:after,
    &:checked + span.label:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        left: 0px;
        top: -3px;
        background-image: url("~theme-assets/svg/check.svg");
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: center;
        margin-top: 0;
    }
}
