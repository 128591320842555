/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _product contains {less} for products
 *
 */

.#{$prefix}-product {
  background: none;
  li.item,
  .item li {
    list-style: none;
    margin-left: 0;
    width: 100%;
    .product-image img {
      max-width: 100%;
      height: auto;
    }
  }
  ul.add-to-links {
    padding-left: 0;
  }

  &.products-grid {
    margin: 0;
    padding: 0;
    width: auto;
    .item {
      margin: 0 0 $bf-margin;
      .actions {
        display: block;
      }
    }
  }
  &.products-list {
    margin: 0;
    padding: 0;
    width: auto;
  }
}

.#{$prefix}-product-single {
  .products .product {
    width: 100%;
    padding-left: 0;
  }
}
