body.checkout-index-index,
body.checkout-cart-index {

    #checkout {
        background: none;

        .authentication-wrapper {
            margin-top: 0;
        }
    }

    .checkout-block {
        box-shadow: none;

        .checkout-shipping-method {
            .checkout-shipping-method {
                .step-title {
                    margin-bottom: 1rem;
                }

                .myparcel-delivery-options {
                    &__input {
                        input[type="radio"],
                        input[type="checkbox"] {
                            opacity: 1;
                            position: relative;
                            accent-color: $christi;
                        }
                    }
                }
            }
        }
    }

    .checkout-header {
        display: none;
    }

    .checkout-block:hover {
        box-shadow: none;
    }

    .cart-container {
        @media screen and (min-width: 768px) and (max-width: 1024px) {
            .cart-summary {
                width: 40%;
            }
            .form.form-cart {
                width: 58%;
            }
        }

        .cart.items.data.table {
            .item-actions {
                a.action.action-edit,
                .action.action-delete {
                    @extend %button-ghost-default;
                    @extend %button-ghost-default--download;
                    appearance: none;
                    padding: 0 15px;

                    &:after {
                        content: none;
                    }
                }
            }
        }

        .cart.main.actions {
            .action.continue,
            .action.clear,
            .action.update {
                @extend %button-ghost-default;
                @extend %button-ghost-default--small;
                appearance: none;

                &:before {
                    line-height: 40px;
                }

                @include media('<=tablet') {
                    width: 100%;
                    display: inline-block;
                    float: left;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .column.main {
        padding-bottom: 0;
    }

    .opc-estimated-wrapper {
        padding-left: 0;

        .minicart-wrapper {
            display: block;
            right: 0;
        }
    }

    .modal-custom .action-close {
        margin: 25px;
    }

    .header__usps {
        @include media('<=phone') {
            display: none;
        }
    }

    .modal-custom.opc-sidebar {
        @include media('<=phone') {
            left: 0;
            margin-top: 0;
            width: 100%;
        }
    }

    .field-tooltip .field-tooltip-content {
        top: 2px;
    }

    @include media('<=tablet') {
        .checkout-container {
            display: flex;
            flex-direction: column;
        }

        aside.opc-sidebar {
            order: -1;
            width: 100%;
        }
    }

    .control .mage-error {
        width: 100%;
    }

    .fieldset > .field:not(.choice) > .control {
        @include media('<=desktop') {
            width: 100%;
        }
    }

    .page-title-wrapper {
        height: auto;
    }

    .opc-sidebar {
        margin: 26px 0 20px;
        width: 50%;
    }

    .opc-wrapper {
        margin-top: 28px;
        width: 100%;
        @include media('>=desktop') {
            //controleren
            width: 100%;
        }
        @include media('<=tablet') {
            //controleren
            //padding: 15px;
            width: 100%;
        }
    }

    .checkout-payment-method .payment-method-content {
        padding-left: 0;
    }

    .fieldset > .field:not(.choice) > .label {
        text-align: left;
        line-height: 25px;
        padding: 0;
        font-size: 13px;
        display: block;
        width: 100%;
        @extend %label;
        @extend %label--small;
        @include media('<=desktop') {
            display: block;
            width: 100%;
        }

        &:after {
            top: -3px;
        }
    }

    .fieldset > .field {
        @include media('<=desktop') {
            margin: 0 0 10px 0;
        }
    }

    .fieldset > .field .additional,
    .fieldset > .fields > .field .additional {
        display: none;
    }

    .fieldset > .field .note,
    .fieldset > .fields > .field .note {
        background-color: $infobox-bg;
        border: 1px solid $infobox-content;
        color: $infobox-content;
        margin: 10px 0;
        padding: 5px 20px;
        position: relative;
        text-align: center;
        width: 100%;

        &:before {
            content: '';
        }

        &:after {
            border-bottom: 8px solid $infobox-content;
            content: '';
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            position: absolute;
            top: -8px;
            left: calc(50% - 8px);
        }
    }

    .opc-wrapper .form-login,
    .opc-wrapper .form-shipping-address,
    .checkout-payment-method .field-select-billing,
    .checkout-payment-method .billing-address-form {
        max-width: 100%;
    }

    .table-checkout-shipping-method {
        min-width: 100%;

        tr,
        tr td {
            cursor: pointer;
        }
    }

    .opc-wrapper .form-shipping-address {
        margin-top: 0;
    }

    .checkout-payment-method .payment-method-title {
        border-top: none;
    }

    .checkout-payment-method .payment-option-title {
        border-top: 1px solid $default-border-color;
    }

    .abs-checkout-title,
    .opc-wrapper .step-title,
    .opc-block-shipping-information .shipping-information-title,
    .opc-block-summary > .title,
    .checkout-agreements-items .checkout-agreements-item-title {
        @extend %h3;
        @extend %h3--primary;
        border-bottom: 1px solid $default-border-color;
    }

    .checkout-payment-method .payment-option-content,
    .checkout-payment-method .payment-option-title {
        padding-left: 0;
    }

    div[name='shippingAddress.country_id'] .control,
    div[name='shippingAddress.prefix'] .control,
    div[name='billingAddresscheckmo.country_id'] .control,
    div[name='shippingAddress.region_id'] .control {
        border: 1px solid rgba($gainsboro, 0.5);
        border-radius: 3px;
        color: rgba($cod-gray, 0.5);
        font-size: 15px;
        height: 50px;
        line-height: 45px;
        padding: 0 10px;

        .select {
            border: none;
            @include fontWeightLight();
        }
    }

    .payment-option-content {
        .action-cancel {
            background-color: $error-bg;
            border: none;
            color: $error-content;
            height: 50px;
        }

        .action-apply {
            border: none;
            @extend %button-primary;
        }
    }

    .action.primary.checkout {
        @extend %button-primary;
    }

    .checkout-payment-method
    .payment-method-content
    > .actions-toolbar
    > .primary
    .action.primary,
    .methods-shipping .actions-toolbar .action.primary {
        @extend %button-primary;
        border: none;
        padding: 0 60px 0 30px;
    }

    .checkout-billing-address .actions-toolbar .primary {
        border-bottom: 1px solid $default-border-color;
        margin: 20px 0;
        padding-bottom: 20px;
        width: 100%;

        button {
            display: block;
            border: none;

            &.action-cancel {
                color: $error-content;
                height: 50px;
                text-decoration: underline;

                &:before {
                    content: '× ';
                }
            }

            &.action-update {
                @extend %button-secondary;
            }
        }
    }

    #checkout-step-payment {
        @include media('<=phone') {
            padding: 0 15px;
        }
    }

    .opc-block-summary {
        background: $white;
        display: flex;
        flex-direction: column;
        padding: 0;

        .table-totals {
            background-color: $desert-storm;
            margin-bottom: 0;
            order: 2;

            tr {
                border-bottom: 1px solid $concrete;
                height: 70px;

                &:last-child {
                    border-bottom: none;
                }

                th {
                    padding: 0 0 0 30px;
                    vertical-align: middle;
                }

                td,
                td.amount {
                    padding: 0 30px 0 0;
                    vertical-align: middle;
                }

                &.totals.discount {
                    background-color: $error-bg;
                    border-top: 2px solid $error-bg;
                    border-bottom: 2px solid $error-bg;
                    color: $error-content;

                    th.mark {
                        span {
                            &.title {
                                padding: 0;
                                font-size: inherit;
                                @include fontWeightBold();
                                background: none;
                                color: $error-content;

                                &:after {
                                    content: ':';
                                }
                            }
                        }
                    }
                }

                &.grand.totals {
                    th,
                    td {
                        color: $christi;
                        @include fontWeightBold();
                    }

                    .mark {
                        padding-top: 0;
                    }
                }
            }
        }

        span.title {
            background: $christi;
            background-image: linear-gradient(
                    -90deg,
                    $lima 1%,
                    rgba(129, 195, 14, 0) 100%
            );
            border-bottom: none;
            color: $white;
            font-size: 24px;
            padding: 30px 20px;
        }

        .items-in-cart {
            padding: 15px 20px;

            > .title {
                border-bottom: none;
            }

            &.active > .title {
                border-bottom: 1px solid $default-border-color;
            }

            .item-options {
                dt:after {
                    content: ':';
                }

                dt,
                dd {
                    display: inline-block;
                }
            }
        }
    }

    .opc-block-shipping-information {
        padding: 0;

        .shipping-information {
            background: $white;
            border: 1px solid $default-border-color;
            box-shadow: 0 0 25px 0 rgba($black, 0.05);
            padding: 30px;
        }
    }

    .checkout-success {
        margin: 30px 0;
    }

    .checkout-success .action.primary,
    #registration .action.primary {
        @extend %button-ghost-default;
        background-color: transparent;
        @include media('<=phone') {
            width: 100%;
        }
    }

    .checkout-success .actions-toolbar {
        margin-top: 15px;
    }

    #registration .action.primary {
        margin: 15px 0;
    }

    .checkout-usps {
        background: $white;
        height: 80px;
    }

    .payment-method-content {
        .fieldset > .field:not(.choice) > .label {
            line-height: inherit;
            width: 100%;
            margin-bottom: 20px;
        }

        .fieldset > .field:not(.choice) > .control {
            float: none;
        }
    }

    .checkout-payment-method .payment-option-content .payment-option-inner + .actions-toolbar {
        float: right;
        margin-bottom: 20px;
    }

    .select {
        //border: 1px solid rgba(221, 221, 221, 0.5);
        //border-radius: 3px;
        //color: rgba(48, 48, 48, 0.5);
        //font-size: 15px;
        //height: 50px;
        //line-height: 45px;
        //padding: 0 10px;
    }

    .secondary .action.remind {
        margin-top: 18px;
    }

    .column:not(.sidebar-additional) form .actions-toolbar,
    .column:not(.sidebar-main) form .actions-toolbar {
        margin-left: 0;
        margin-bottom: 15px;
    }

    .block-authentication .actions-toolbar > .primary {
        margin-top: 15px;
    }

    .cart-container {
        margin-bottom: 30px;

        .form-cart .actions > * {
            margin-top: 10px;
            @include media('<=tablet') {
                margin-top: 0;
            }
        }
    }

    .tig_buckaroo_creditcard .payment-method-second-col .card-types-input {
        opacity: 1;
        position: relative;
        height: inherit;
        line-height: inherit;
    }

}

.form-newsletter-manage .fieldset > .field.choice:before, .fieldset > .field.no-label:before {
    display: none;
}


.column:not(.sidebar-main) form .actions-toolbar {
    margin-left: 0;
}


.cart.table-wrapper .product {
    &-item-photo {
        display: block;
        float: left;
        position: inherit;
        top: -15px;
        vertical-align: top;
        margin-left: 10px;
        max-width: 60px;
        width: inherit;
    }

    &-item-details {
        display: block;
        float: left;
        vertical-align: top;
        white-space: normal;
        width: inherit;
    }
}

.cart.table-wrapper .col.item .action.action-edit {
    display: none;
}

.cart.table-wrapper .col.item .action.action-delete {
    @extend %delete;
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    z-index: 2;
    top: 20px;
    left: 0;

    span {
        display: none;
    }
}

body[class^="checkout"] {
    .nav-sections {
        display: none;
    }

    &:after {
        border-bottom: 5px solid $christi;
        content: '';
        left: 0;
        margin-top: 20px;
        position: absolute;
        width: 100%;
    }

    .page-main {
        padding-top: 20px;
    }

    .action.primary.checkout {
        @include media("<=desktop", ">tablet") {
            padding: 0 15px;
        }
    }
}

body.checkout-cart-index .cart-container .cart.main.actions .action.continue,
body.checkout-cart-index .cart-container .cart.main.actions .action.update {
    padding: 0 30px 0 10px;
}

.cart.table-wrapper .col.qty .input-text {
    padding: 0 5px;
    width: 50px;
}

.cart-summary .active.block.active > .title:after,
.cart-summary .discount.block > .title:after {
    transform: rotate(-90deg);
}

.cart-summary {
    padding: 25px 15px;
    @include media("<=phone") {
        margin-top: 30px;
        order: 100;
    }
}

.cart.table-wrapper .item .col.item {
    @include media("<=phone") {
        padding: 25px 0 10px 10px;
    }
}

.cart-container {
    @include media("<=phone") {
        display: flex;
        flex-direction: column;
    }
}

.cart.table-wrapper .col.price {
    padding-top: 27px;
}

.cart.table-wrapper .col.qty {
    padding-top: 17px;
}

.cart-summary .discount {
    display: none;
}

#shopping-cart-table th,
.price-excluding-tax .price, .price-including-tax .price {
    font-weight: 300;
}

.checkout-payment-method .payment-method-billing-address .billing-address-details {
    border-top: 1px solid $default-border-color;
    padding: 10px 0 0 0;
}

.opc-wrapper .fieldset > .field._required > label:after,
.opc-wrapper .fieldset > .field.required > label:after {
    margin-left: 0;
}

.fieldset > .field:not(.choice) > .control {
    width: 100%;
}

#checkout-step-shipping {
    .field.street.required,
    .fieldset.address [name$=".city"],
    .fieldset.address [name$=".adress"],
    .fieldset.address [name$=".lastname"],
    .fieldset.address [name$=".firstname"],
    .fieldset.address [name$=".country_id"],
    .fieldset.address [name$=".postcode"] {
        width: 50%;
    }

    .fieldset > .field {
        margin-bottom: 15px;
    }

    .field.street {
        label {
            display: none;
        }
    }
}

.checkout-block .checkout-payment-method {
    display: block !important;
}


.checkout-payment-method .payment-method-title {
    padding: 10px 0;
}

.checkout-index-index .checkout-block {
    margin-bottom: 0;
    padding-top: 0;
}

.block.items-in-cart.active {
    margin-bottom: 0;
}

.cart.table-wrapper .product-item-name {
    @include media('<=phone') {
        margin-left: 10px;
    }
}

.cart.table-wrapper .item .col.item {
    @include media('<=phone') {
        min-height: 125px;
    }
}

.cart.table-wrapper .col {
    padding-top: 27px;
}

.checkout-index-index {
    .page-main {
        @include media('<=tablet') {
            max-width: inherit;
        }
    }

    .authentication-wrapper {
        padding-left: 20px;
        @include media('<=phone') {
            padding-left: 0;
        }
    }
}

#mypa-load {
    input[type="radio"]:checked + label.label:after, input[type="radio"]:checked + span.label:after {
        top: 0;
    }
}

.buckaroo_magento2_ideal .payment-method-second-col .bank-types-input, .buckaroo_magento2_idealprocessing .payment-method-second-col .bank-types-input {
    opacity: 1;
    margin-top: 1px;
    height: 16px;
    margin-left: -16px;
}

[class^="buckaroo_magento2_"] .payment-method-second-col .bank-types-img {
    margin-right: 15px;
}

.bank-types-text {
    padding-left: 18px;
}

[class^="buckaroo_magento2_"] .payment-method-second-col {
    padding-right: 0;
}





