.page-top-wrapper {
  background-color: $desert-storm;
  background-image: linear-gradient(transparent 70%, rgba(0, 0, 0, 0.05) 30%);
  padding: 9px 0;
  background-size: 7px 7px;
  display: block;
  position: relative;
  top: -1px;
  width: 100%;
  z-index: 1;


  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: left;


  @include media('<=phone') {
    height: 130px;
  }

  body[class^='faq-'] & {
    height: auto;
  }

  &:after {
    background: linear-gradient(to right, $desert-storm 40%, transparent 100%);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  h1.page-title,
  h2.page-title {
    margin: 0;
    line-height: 44px;
  }

  p.steps,
  .page-title-wrapper {
    @include container-size;
    position: relative;
    width: 100%;
    z-index: 3;
  }

  .page-title-wrapper {
    padding: 0;
    .page-title-wrapper {
      top: auto;
      transform: none;
    }

    body[class^='faq-'] & {
      transform: none;
    }

    @include media('<=phone') {
      padding: 15px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
    }
  }
  p.steps {
    @extend %p--light;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.catalog-product-view{
  @include media('<=phone'){
  h1.page-title{
    line-height: 30px;
    }
  }
}
