/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _newsletter contains {less} for newsletter subscribe blocks
 *
 */

.#{$prefix}-newsletter {
  &.block {
    .actions {
      .button {
        float: left;
      }
    }
    .block-title {
      border-top: none;
    }
  }
  .input-container {
    position: relative;
    .actions {
      position: absolute;
      right: 0;
      top: 0;
      .button {
        border-right: none;
        border-top: none;
        border-bottom: none;
      }
    }
  }
}
