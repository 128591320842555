.toolbar-bottom {
  clear: both;
  position: relative;
  clear: both;
  .toolbar.toolbar-products {
    display: block;
    .pages {
      display: block;
      text-align: center;
      ul.items.pages-items {
        li.item {
          @extend %button-regular-init;
          color: $cod-gray;
          border-radius: 32px;
          border: 2px solid;
          padding: 0 20px;
          opacity: 0.35;
          a {
            color: $cod-gray;
          }
          &.current {
            @extend %button-regular-init;
            color: $christi;
            opacity: 1;
            strong.page {
              color: $christi;
            }
          }
          &.pages-item-next {
            border: none;
            position: absolute;
            right: 0;
            padding: 0;
            &:after {
              content: '';
              @extend %chevron-gray-right;
              width: 7px;
              height: 10px;
              margin-left: 10px;
              display: inline-block;
            }
            @include media('<=phone') {
              display: none;
            }
          }
          &.pages-item-previous {
            border: none;
            position: absolute;
            left: 0;
            padding: 0;
            &:before {
              content: '';
              @extend %chevron-gray-left;
              width: 7px;
              height: 10px;
              margin-right: 10px;
              display: inline-block;
            }
            @include media('<=phone') {
              display: none;
            }
          }
        }
      }
    }
    .toolbar-amount,
    .toolbar-sorter,
    .modes.field,
    .field.limiter {
      display: none;
    }
  }
}

.item.pages-item-previous,
.item.pages-item-next {
  &::before,
  &::after {
    display: none !important;
  }
}

.pages .action {
  border: none;
  color: rgba(0, 0, 0, 0.35);

  &:hover {
    border: none;
    color: rgba(0, 0, 0, 0.35);
  }

  &.action.previous > span,
  &.action.next > span {
    overflow: visible;
    position: initial;
    @include fontWeightBold();
    color: rgba(black, 0.35);
    font-size: 15px;
  }

  &.action.next {
    width: auto;

    &::before {
      position: absolute;
      right: -19px;
    }

    > span {
      margin-right: 25px;
    }
  }
}
