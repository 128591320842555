.product-page-top-wrapper {
  margin-bottom: 30px;
  @include media('<=phone') {
    display: flex;
    flex-direction: column;
  }

  .kiyoh-box{
    display: none;
  }

    &:after {
        content: "";
        clear: both;
        display: block;
    }
}

.product-info-main {
  @include media('>=tablet') {
    margin-top: -65px;
  }
  @include media('<=phone') {
    order: 1000;
  }
  background-color: $white;
  border-radius: 0 0 3px 3px;
  margin-top: 30px;
  position: relative;
  z-index: 3;

  body.catalog-product-view & {
    background-color: transparent;
  }

  .product-add-form {
    position: relative;

    .options-container {
      background-color: $desert-storm;
      padding: 30px;
    }

    .shadow {
      z-index: -1;
      background-color: rgba($black, 0.25);
      width: calc(100% - 40px);
      position: absolute;
      height: 20px;
      filter: blur(10px);
      bottom: 5px;
      left: 20px;
    }

    div.mage-error[generated] {
      margin: 10px 0;
    }

    &.sticky-box {
      position: fixed;
      top: 140px;
      @include media('<=tablet') {
        position: unset;
      }

      z-index: 15;
    }
  }

  .product.attribute.overview {
    background-color: $desert-storm;
    margin: 0;
  }

  .product-info-main .box-tocart,
  .product-options-bottom .box-tocart {
    margin: 0;
  }

  .box-tocart {
    width: 100%;
    .fieldset {
      @include clearfix();
      > * {
        clear: both;
        width: 100%;
      }
    }
    .field {
      display: block;
      padding: 0;
      width: 100%;
      &:not(.choice) > .label {
        float: left;
        margin: 0;
        @include media('<=phone') {
          margin: 0;
        }
      }
      .control {
        float: right;
        margin: 10px 0;
      }
      &.qty {
        display: block;
        padding: 0;
        .label {
          color: $mineshaft;
          @include fontWeightBold();
          font-size: 16px;
          line-height: 46px;
        }
      }
    }

    .input-text.qty {
      height: 40px;
      width: 120px;
    }

    .actions {
      display: block;
      button.tocart {
        @extend %button-primary;
        @extend %button-primary--large;
        border: none;
        display: block;
        text-align: left;
        width: 100%;
      }
    }
  }
}

body.catalog-product-view {
  .review-field-ratings {
    div.mage-error[generated] {
      top: 18px;
    }
  }

    .breadcrumbs {
        @include container-size;
    }
}

.page-layout-2columns-left,
.page-layout-2columns-right,
.page-layout-3columns {
    .product-info-main {
        @include media (">desktop") {
            width: 31.333333333%;
        }
    }

    .product.media {
        @include media (">desktop") {
            width: 66.6666666666%;
        }
    }
}

.catalog-category-view {
  button.tocart {
    position: relative;

    @include media ("<=desktop") {
      padding-left: 20px !important;
    }

    @include media(">tablet", "<=desktop") {
      font-size: 15px !important;
    }

    @media screen and (min-width: 460px) and (max-width: 520px) {
      font-size: 14px !important;
    }
  }
}

