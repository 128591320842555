.bounce {
    display: inline-block;
    animation-name: bounce;
    animation-duration: .5s;
    transform: scale(1);
}

@keyframes bounce {
    0% { transform: scale(1.1); opacity: 1 }
    50% { transform: scale(1.6); opacity: .7; }
    60% { transform: scale(0.6); opacity: 1 }
    80% { transform: scale(0.95) }
    100% { transform: scale(1) }
}