body {
  font-family: $font-family__sans-serif;
}

%h1 {
  @include font-size($h1-size);
  color: $christi;
  @include fontWeightLight();
}

%h2 {
  @include font-size($h2-size);
  color: $black;
  @include fontWeightLight();
}

%h3 {
  @include font-size($h3-size);
  color: $black;
  @include fontWeightLight();
  &--inversed {
    color: $white;
  }
  &--primary {
    color: $fern;
  }
}

%h4 {
  @include font-size($h4-size);
  color: $black;
  @include fontWeightLight();
}

%h5 {
  @include font-size($h5-size);
  color: $black;
  @include fontWeightLight();
  &--inversed-50 {
    color: rgba($white, .5);
  }
  &--50 {
    color: rgba($black, .5);
  }
}

h6,
%h6 {
  @include font-size($h6-size);
  color: $black;
  @include fontWeightLight();
}

%p {
  @include font-size($p-size);
  color: $mineshaft;
  @include fontWeightLight();
  &--light {
    color: rgba($mineshaft, .65);
  }
  &--light-centered {
    color: rgba($mineshaft, .65);
    text-align: center;
  }
  &--intro {
    @include font-size($middle-size);
    color: rgba($cod-gray, .5);
  }
}

%button-text {
  &--primary {
    @include font-size($h5-size);
    color: $christi;
    @include fontWeightBold();
  }
  &--inversed--small {
    @include font-size($small-size);
    color: $white;
  }
  &--textcolor-50 {
    @include font-size($h5-size);
    color: rgba($cod-gray, .5);
  }
}

%label {
  @include font-size($h5-size);
  color: $mineshaft;
  @include fontWeightBold();
  &--small {
    @include font-size($p-size);
    color: rgba($mineshaft, .65);
  }
}

%tabs {
  @include font-size($mini-size);
  @include fontWeightBold();
  color: $mineshaft;
  &--active {
    @include font-size($mini-size);
    @include fontWeightBold();
    color: $christi;
  }
}

%product-title {
  @include font-size($h5-size);
  @include fontWeightLight();
  color: rgba($mineshaft, .5);

  &--centered {
    text-align: center;
  }
}

%page-title {
  @include font-size($super-size);
  @include fontWeightLight();
  color: $christi;
}

%breadcrumbs {
  @include font-size($nano-size);
  color: rgba($cod-gray, .5);
  @include fontWeightLight();
}

%navigation--dropdown {
  @include font-size($h5-size);
  color: $christi;
  @include fontWeightBold();
  &-list {
    @include font-size($p-size);
    color: $cod-gray;
    font-family: $font-family__sans-serif;
  }
}

%faq-item {
  @include font-size($mini-size);
  color: $chicago;
  @include fontWeightLight();
  &--active {
    color: $fern;
    font-family: $font-family__sans-serif;
  }
}

%price {
  @include font-size($price-size);
  color: $ucla-gold;
  @include fontWeightLight();
  &--old {
    @include font-size($h3-size);
    color: rgba($cod-gray, .25);
    position: relative;
  }
}

%usp {
  @include font-size($p-size);
  color: rgba($cod-gray, .4);
  font-family: $font-family__serif;
  &-list {
    @include font-size($p-size);
    color: rgba($cod-gray, .8);
    @include fontWeightLight();
  }
}

%mobile--usp {
  @include font-size($micro-size);
  color: rgba($cod-gray, .4);
  font-family: $font-family__serif;
}

%list--checklist {
  @include font-size($p-size);
  color: rgba($cod-gray, .65);
  @include fontWeightLight();
}

%light-65 {
  @include font-size($light-65-size);
  color: rgba($mineshaft, .65);
  @include fontWeightLight();
}
