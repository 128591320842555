.box-tocart {
  @include clearfix;

  .price-label {
    display: none;
  }

  .price-container.price-final_price {
    float: right;
    .price {
      @extend %price;
    }
  }

  .special-price {
    float: right;
    .price-final_price .price-wrapper .price {
      @extend %price;
    }
  }

  .old-price {
    float: left;
    margin: 3px 0 0 0;
    text-decoration: none;
    position: relative;

    &:after {
      content: '';
      background-color: rgba($cod-gray, .25);
      height: 1px;
      width: 100%;
      display: block;
      position: absolute;
      top: 30px;
    }

    .price-final_price {
      margin: 0;
      .price-wrapper .price {
        @extend %price--old;
        line-height: 60px;
      }
    }
  }
}
