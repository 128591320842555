// FLEX ALIGN https://css-tricks.com/almanac/properties/j/justify-content/
%div__flex {
  -ms-box-orient: horizontal;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

%div__flex--start {
  justify-content: flex-start;
}

%div__flex--end {
  justify-content: flex-end;
}

%div__flex--center {
  justify-content: center;
}

%div__flex--space-between {
  justify-content: space-between;
}

%div__flex--space-around {
  justify-content: space-around;
}

%gaussian-shadow {
  background-color: rgba($black, .15);
  border-radius: 3px;
  content: '';
  bottom: -10px;
  filter: blur(10px);
  height: 40px;
  left: 20px;
  opacity: 1;
  position: absolute;
  width: calc(100% - 40px);
}

// ERROR //
%message {
  /* Messages */
  &__info {
    background-color: $infobox-bg !important;
    border: 1px solid $infobox-content !important;
    color: $infobox-content !important;
  }
  &__success {
    background-color: $succes-bg !important;
    border: 1px solid $succes-content !important;
    color: $succes-content !important;
  }
  &__warning {
    background-color: $warning-bg !important;
    border: 1px solid $warning-content !important;
    color: $warning-content !important;
  }
  &__error {
    background-color: $error-bg !important;
    border: 1px solid $error-content !important;
    color: $error-content !important;
  }
}
