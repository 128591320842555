.sidebar-main {
  .block-title.filter-title {
    display: none;
    visibility: hidden;
    @include media('<=tablet') {
      display: block;
      visibility: visible;
    }
    strong {
      @extend %button-secondary;
      cursor: pointer;
      margin-top: 20px;
      &:before {
        background: url('../svg/filter.svg') no-repeat center;
        content: '';
        display: inline-block;
        height: 13px;
        margin-right: 10px;
        width: 14px;
      }
    }
  }
}

#amasty-shopby-product-list h3 {
  margin-top: 0;
}

#amasty-shopby-product-list .catalog-topnav.amasty-catalog-topnav {
  display: none;
}

.catalog-topnav.amasty-catalog-topnav {
  display: none;
  @include media('<=tablet') {
    display: block;
  }
}

@include media('<=tablet') {
  .block.filter {
    margin-bottom: 0;
    &.active {
      .filter-content {
        background: $white;
        display: block;
        margin-top: 20px;
        padding: 10px 20px 0 20px;
      }
      .block-title.filter-title strong:after {
        transform: rotate(90deg);
      }
    }
    .filter-content {
      display: none;
    }
    .filter-options .filter-block-container:last-child {
      border-bottom: none;
    }
  }
}
