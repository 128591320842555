input[type="checkbox"] {

  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  .actions-secondary &.compare-select + span.label{
      &:before {
          top: 0;
          margin-right: 13px;
      }
      &:after {
          top: 0;
      }
  }

  & + label,
  & + span.label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before,
  & + span.label:before {
    content: '';
    position: relative;
    top: -4px;
    margin-right: 20px;
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    background-color: $white;
    border-radius: 3px;
    border: 1px solid $gainsboro;
  }

  // Box hover
  &:hover + label:before,
  &:hover + span.label:before {
    background-color: $gainsboro;
  }

  // Box focus
  &:focus + label:before,
  &:focus + span.label:before {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before,
  &:checked + span.label:before {
    background-color: $white;
  }

  // Disabled state span.label.
  &:disabled + label,
  &:disabled + span.label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before,
  &:disabled + span.label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after,
  &:checked + span.label:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: -3px;
    background-image: url("~theme-assets/svg/check.svg");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center;
  }
}
