.page-header {
    background-color: $christi;
    border: none;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 150;
    margin-bottom: 0;

    .panel.wrapper {
        border-bottom: none;

        .block-search {
            z-index: 5;

            .minisearch {
                .search-autocomplete {
                    display: none !important;
                }
            }
        }
    }

    .panel.header {
        @include container-size;
        @include media('<=tablet') {
            height: 60px; // reference $3
            position: relative;
            padding-left: 15px;
            margin: 0;
            max-width: inherit;
        }
        lost-utility: clearfix;
        height: 60px; // reference $1
        width: 100%;
        transition: height 0.2s ease-in;
        padding-top: 0px;

        &.show-search {
            @include media('<=tablet') {
                height: 120px;
            }
        }

        .logo {
            margin: 0;
            max-width: 100%;
            padding: 15px 0;

            body:not(.gplighting):not(.gplightingfr):not(.gplightingde) & {
                padding: 18px 0;
            }

            img {
                height: 23px;

                body[class*=gplighting] & {
                    height: 28px;
                }
            }

            @include media('<=phone') {
                img {
                    display: none;
                }
                &:after {
                    background: url('../svg/logo-gp.svg') no-repeat center;
                    content: '';
                    display: block;
                    height: 50px;
                    left: 0;
                    position: absolute;
                    top: 5px;
                    width: 50px;

                    body.checkout-cart-index & {
                        left: calc(50% - 25px);
                    }
                }
            }
        }

        .header.content {
            padding: 0;
            float: left;
            @include media('<=tablet') {
                float: inherit;
                width: 100%;
                height: 100%;
            }

            .authorization-link-mobile {
                position: absolute;
                top: 30px;
                transform: translateY(-50%);
                right: 191px;
                list-style-type: none;
                display: none;
                @include media('<=tablet') {
                    display: block;
                }

                a {
                    height: 40px;
                    width: 40px;
                    background-color: white;
                    border-radius: 50%;
                    display: block;
                    position: relative;

                    &::before {
                        content: '';
                        background-image: url('../images/icon-user.svg');
                        speak: none;
                        height: 20px;
                        width: 20px;
                        background-size: cover;
                        top: 50%;
                        left: 50%;
                        position: absolute;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        .header.links {
            float: right;
        }

        .minicart-wrapper {
            float: right;
        }
    }
}

body[class^="checkout"] .page-header .panel.header .header.content .authorization-link-mobile {
    right: 15px;
}

.header.panel > .header.links {
    margin: 0 5px 0 22px;

    > li {
        margin: 0;

        &.item.link.compare,
        &.greet.welcome {
            display: none;
        }

        a {
            color: $white;
            line-height: 60px;
            font-size: 15px;
            @include fontWeightBold();
        }
    }
}

body.checkout-index-index,
body.checkout-cart-index {
    .minicart-wrapper,
    .block.block-search,
    .products-menu {
        display: none;
    }

    .panel.header {
        height: 60px;
        padding-bottom: 0;
        padding-top: 0;

        @include media('<=tablet') {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .page-header .panel.header .header.links {
        float: left;
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 2;

        li {
            display: none;
        }
    }
}

.panel.header .links,
.panel.header .switcher {
    @include media('<=tablet') {
        display: none;
    }
}

@include media('>tablet') {
    .panel.header .links,
    .panel.header .switcher {
        display: inline-block;
    }
}
