.message {
  box-sizing: border-box;
  a {
    @include fontWeightBold();
    text-decoration: underline;
  }
  &.info {
    background-color: $infobox-bg;
    border: 1px solid $infobox-content;
    color: $infobox-content;
    margin: 10px 0 30px 0;
    a,
    a:hover,
    > *:first-child:before {
      color: $infobox-content;
    }
  }
  &.error {
    background-color: $error-bg;
    border: 1px solid $error-content;
    color: $error-content;
    margin: 10px 0 30px 0;
    a,
    a:hover,
    > *:first-child:before {
      color: $error-content;
    }
  }
  &.warning,
  &.notice {
    background-color: $warning-bg;
    border: 1px solid $warning-content;
    color: $warning-content;
    margin: 10px 0 30px 0;
    a,
    a:hover,
    > *:first-child:before {
      color: $warning-content;
    }
  }
  &.success {
    background-color: $succes-bg;
    border: 1px solid $succes-content;
    color: $succes-content;
    a,
    a:hover,
    > *:first-child:before {
      color: $succes-content;
    }
  }

  &.info,
  &.error,
  &.warning,
  &.notice,
  &.success {
    margin: 10px 0 30px 0;
  }
}

.header__usps--wrapper .message{
  margin: 0;
}

.product-info-main .messages {
  .message {
    margin: 10px 0;

    &:first-child {
      margin: 40px 0 10px 0;
    }

    &:after {
      content: '';
      width: 0px;
      height: 0px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      position: absolute;
      bottom: -8px;
      left: calc(50% - 8px);
    }

    &.info:after {
      border-top: 8px solid $infobox-content;
    }

    &.error:after {
      border-top: 8px solid $error-content;
    }

    &.warning:after,
    &.notice:after {
      border-top: 8px solid $warning-content;
    }

    &.success:after {
      border-top: 8px solid $succes-content;
    }
  }
}
