.products-grid {
    #block-related-heading {
        @extend %h3;
        @extend %h3--primary;
        margin: 0 0 40px;
        display: block;
        font-weight: 200;
    }

    h3 {
        padding-left: 20px;
    }

    > .products {
        display: flex;
        flex-wrap: wrap;
        margin: 0 !important;
    }

    li.item.product.product-item {
        background: $white;
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% * 1 / 3 - 12.666666666666668px);
        max-width: calc(100% * 1 / 3 - 12.666666666666668px);
        width: calc(100% * 1 / 3 - 12.666666666666668px);

        &:nth-child(n) {
            margin-right: 19px;
            margin-left: 0;
        }

        &:nth-child(3n) {
            margin-right: 0;
            margin-left: auto;
        }

        &:nth-child(3n+1) {
            clear: both;
        }

        .product-item-info {
            width: auto;
        }

        body.catalog-category-view &,
        .catalogsearch-result-index & {
            // 1px minder voor IE/edge fix
            @include media('<=tablet') {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: calc(100% * 1 / 2 - 6px);
                max-width: calc(100% * 1 / 2 - 6px);
                width: calc(100% * 1 / 2 - 6px);

                &:nth-child(n) {
                    margin-right: 12px;
                    margin-left: 0;
                }

                &:nth-child(2n) {
                    margin-right: 0;
                    margin-left: auto;
                }
            }

            @include media('<=phone') {
                lost-column: 1/2 2 12px flex;
            }

            @media screen and (max-width: 475px) {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: calc(100% * 1 / 1 - 0px);
                max-width: calc(100% * 1 / 1 - 0px);
                width: calc(100% * 1 / 1 - 0px);
            }
        }

        .product-image-container {
            width: 100%;

            .product-image-wrapper {
                height: auto;
                overflow: visible;
                padding: 20px 0 0;

                .product-image-photo {
                    bottom: auto;
                    display: block;
                    height: auto;
                    left: auto;
                    margin: auto;
                    max-width: 100%;
                    position: static;
                    right: auto;
                    top: auto;
                    max-height: 250px;
                    max-width: 200px;
                }
            }
        }

        .product-item-details {
            padding: 20px;

            .product-item-name {
                @extend %product-title;
            }

            .price {
                @extend %price;
                font-size: 20px;
                line-height: 24px;
            }
        }

        .price-box {
            margin: 0;
        }
    }
}

.catalogsearch-result-index .products-grid li.item.product.product-item:nth-child(3n) {
    margin-right: -1px;
}

.catalogsearch-result-index {
    .search__result-tabs {
        display: none;
    }

    input[type='checkbox'] + span.label {
        display: block;
        background-color: #f8f8f8;
        padding-left: 20px;
        font-size: 16px;
        color: RGB(165, 165, 165);
    }

    .actions-secondary input[type='checkbox'].compare-select + span.label:before {
        top: -3px;
    }

    .product-reviews-summary {
        padding: 15px 20px;
        margin: 0;
        float: left;
        display: table;

        a {
            font-size: 12px;
        }
    }

    .product--usps {
        ul {
            list-style-type: none;

            li {
                position: relative;
            }
        }
    }
}

@include media('<=tablet') {
    .cms-index-index .bestsellers-row {
        li.item.product.product-item {
            //lost-column: 1/2 2 20px;

            .product-image-photo {
                padding: 0 5px;
            }
        }

        #amasty-shopby-product-list {
            margin-right: 20px;
        }
    }
}


@include media('<=phone') {
    .cms-index-index .bestsellers-row {
        li.item.product.product-item {
            margin-bottom: 20px;
        }

        #amasty-shopby-product-list {
            margin-right: 20px;
        }
    }
}

.bestsellers-row {
    h3, .product-items {
        padding-left: 0;
    }

    .products-grid > .products {
        @include media("<phone") {
            flex-wrap: nowrap;
        }
    }

    li.item.product.product-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% * 1/4 - 14.25px);
        max-width: calc(100% * 1/4 - 14.25px);
        width: calc(100% * 1/4 - 14.25px);

        &:nth-child(n) {
            margin-right: 19px;
            margin-left: 0;
        }

        @include media('<tablet') {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(100% * 1/2 - 14.5px);
            max-width: calc(100% * 1/2 - 14.5px);
            width: calc(100% * 1/2 - 14.5px);


            &:nth-child(n) {
                margin-right: 29px;
                margin-left: 0;
            }
        }

        @include media('<phone') {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 100%;
            max-width: 100%;
            width: 100%;
        }
    }
}
