/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _hr contains {less} for horizontal rules
 *
 */

.#{$prefix}-hr {
  margin: $bf-margin auto;
  max-width: 100%;
  width: 100%;
  border-width: 1px;
  border: none;
  border-top: solid;
}
