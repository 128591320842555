/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _column contains {less} for columns
 *
 */

/*-- Generate column widths based on column_count --*/

@lost gutter 40px;
@lost rounder 100;

@mixin column-width($column_count) {
    $step: 5;
    $from: 0;
    $through: 40;
    $unit: 'px';

    @for $i from 1 through $column_count {
        .#{$prefix}-column-#{$i} {
            lost-column: $i / $column_count $column_count / $i;

            @for $c from 1 through 5 {
                .columns-#{$c} & {
                    lost-column: $i / $column_count $c;
                }
            }

            @include media('>phone', '<=tablet') {
                @if ($i != 12 and $i % 2 != 0) {
                    lost-column: 1/1;
                } @else {
                    lost-column: 1/2 2;
                }

                &.bluefoot-fixed-height-item {
                    height: auto !important;
                }
            }

            @include media('<=phone') {
                lost-column: 1/1;

                &.bluefoot-fixed-height-item {
                    height: auto !important;
                }
            }

            @for $g from $from through $through {
                $g: $g * $step;

                @if ($g <= $through) {
                    .gutter-#{$g} & {
                        lost-column: $i / $column_count $column_count / $i $g#{$unit};

                        @include media('>phone', '<=tablet') {
                            @if ($i < ($column_count/2)) {
                                lost-column: 1/2 2 $g#{$unit};
                            } @else {
                                lost-column: 1/1 1 $g#{$unit};
                                &.bluefoot-fixed-height-item {
                                    height: auto !important;
                                }
                            }
                        }

                        @include media('<=phone') {
                            lost-column: 1/1 1 $g#{$unit};

                            &.bluefoot-fixed-height-item {
                                height: auto !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@mixin column-offset($column_count) {
    $step: 5;
    $from: 0;
    $through: 40;
    $unit: 'px';

    @for $i from 1 through $column_count {
        .#{$prefix}-column-#{$i}-offset {
            lost-offset: $i / $column_count;

            @for $g from $from through $through {
                $g: $g * $step;

                @if ($g <= $through) {
                    .gutter-#{$g} & {
                        lost-offset: $i / $column_count row $g#{$unit};
                    }
                }
            }
        }
    }
}

.#{$prefix}-column {
    float: left;
    padding: 0;
    box-sizing: border-box;
    min-height: 1px;
    &.right {
        float: right;
    }
    &.#{$prefix}-no-padding {
        padding: 0;
    }
}

@include column-width($column_count);

@include column-offset($column_count);

@include media('<=phone') {
    .#{$prefix}-column {
        padding: 0;
    }
}
