.fotorama__stage {
  margin: 60px 0 40px;

  .fotorama--fullscreen & {
    margin: 0;
  }
}

.fotorama {
  padding: 0 30px;
}

.fotorama__thumb {
  background-color: $white;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.fotorama__nav--thumbs {
  .fotorama__nav__frame {
    height: 130px !important;
  }
}

.fotorama__nav-wrap {
  .fotorama_vertical_ratio .fotorama__img {
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .video-thumb-icon {
    .fotorama__loaded--img {
      img {
        height: auto;
        display: block;
        max-width: 100%;
        width: auto;
        max-height: 130px;
      }
    }
  }
}

.fotorama__thumb-border {
  border-color: $thumbnail-border;
  border-radius: 5px;
  width: 180px;
  height: 130px !important;
  background: none;
}

.fotorama__nav {
  height: 174px !important;
}

.fotorama__nav.fotorama__shadows--right:after,
.fotorama__stage.fotorama__shadows--right:after {
  background-image: linear-gradient(
      transparent,
      rgba(0, 0, 0, 0.1) 25%,
      rgba(0, 0, 0, 0.15) 75%,
      transparent
    ),
    radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.2), transparent);
}

.fotorama__nav.fotorama__shadows--left:before,
.fotorama__stage.fotorama__shadows--left:before {
  background-image: linear-gradient(
      transparent,
      rgba(0, 0, 0, 0.1) 25%,
      rgba(0, 0, 0, 0.15) 75%,
      transparent
    ),
    radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.2), transparent);
}

.catalog-product-view .fotorama-video-container{
  height: inherit;
  width: inherit;

  &:after{
    background: url('~theme-assets/svg/playbutton.svg') no-repeat center;
    content: '';
    border: none;
  }

  &:before{
    display: none;
  }
}

.fotorama__caption__wrap{
  display: none !important;
}

.fotorama__arr {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 80px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 900;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
  transition: background-color 0.3s ease-in-out;
}

.fotorama__arr.fotorama__arr--prev,
.fotorama__arr.fotorama__arr--next{
  display: block !important;
}
