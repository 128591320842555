.select {
  border: 1px solid $gainsboro;
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $white inset; // remove chrome yellow background
  }
}

.build-select ul {
  @include media("<=phone") {
    display: none;
  }
  li {
    @include media("<=phone") {
      cursor: pointer;
      &.option {
        list-style: none;
        z-index: 1;
      }
    }
  }
}
