.form.password.reset,
.form.password.forget{
    margin: 60px 0;

    fieldset.fieldset {
        label.label {
            display: block;
            width: 100%;
            text-align: left;
            float: none;
            @extend %label;
            @extend %label--small;
        }
        .control {
            width: 100%;
            input {
                border-color: $gainsboro;
                &:active,
                &:focus {
                    box-shadow: 0 2px 4px 0 rgba($black, 0.50);
                }
            }
        }
        .actions-toolbar {
            margin-left: 0;
        }
        &:after {
            content: none;
        }
    }


    .actions-toolbar {
        @include media ("<=phone") {
            @include clearfix();
        }
        margin-left: 0;
        .primary {
            @include media ("<=phone") {
                float: left;
                text-align: left;
            }
            button {
                @extend %button-primary;
                border: none;
                @include media ("<=phone") {
                    width: auto;
                }
            }
        }
        .secondary {
            @include media ("<=phone") {
                float: left;
                margin-top: 15px;
                margin-left: 10px;
                width: auto;
            }
            display: block;
            text-align: left;
            margin-top: 12px;
            width: 50%;
            a.action.back {
                float: right;
                font-size: 13px;
                @include fontWeightLight();
                color: $cod-gray;
                opacity: 0.5;
                text-decoration: underline;
            }
        }
    }
}
