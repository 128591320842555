/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _code contains {less} for code
 * Powered by Highlight js https://highlightjs.org/ and the github theme
 *
 */

.#{$prefix}-code {
  text-align: left !important;
  font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 13px;
  tab-size: 8;
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  pre {
    padding: 0;
    border: none;
    background: none;
  }
  code {
    padding: $bf-margin;
    box-sizing: border-box;
  }
}
