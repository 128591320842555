input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
  border: 1px solid $gainsboro;
  @include font();
  color: rgba($cod-gray, .5);
  &:focus {
    color: rgba($cod-gray, .5);
    font-style: normal;
  }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  color: inherit;
  -webkit-box-shadow: 0 0 0px 1000px $white inset; // remove chrome yellow background
}

._keyfocus *:focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
  box-shadow: none;
}

.field .control input,
.field .control textarea {
  padding: 0 20px;
  color: rgba($cod-gray, 0.5);
  border-radius: 3px;
  @include fontWeightLight();
  font-size: 15px;
  border: 1px solid rgba($gainsboro, 0.5);
}

.field .control input {
  height: 50px;
  line-height: 50px;
}

.field .control textarea {
  padding: 10px 20px;
}

.mage-error {
  @extend %message__error;
  padding: 10px;
  .control & {
    width: 95%;
  }
}

input,
select,
textarea {
  &.mage-error {
    @extend %message__error;
  }
}

.abs-field-tooltip input,
.field .control._with-tooltip input {
  margin: inherit;
  width: 100%;
}
