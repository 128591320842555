body.cms-page-view .page-main {
    max-width: 100%;
    width: 100%;
    margin: 0;
}

body.cms-page-view .page-wrapper--custom-header {
    display: block;
    margin-top: -10px;
    position: relative;
    width: 100%;

    img {
        display: block;
        position: relative;
        margin: 0 auto;
    }
}

body.cms-page-view .breadcrumbs--wrapper {
    background-color: $desert-storm;
    border-color: $mercury;
}

body.cms-page-view .breadcrumbs--wrapper {
    background-color: $desert-storm;
    border-color: $mercury;
}

body.cms-page-view .bluefoot-row.intro {
    background-color: $desert-storm;
}

body.cms-page-view .bluefoot-row {
    padding: 60px 0;
}

body.faq-category-view {
    .page-main {
        padding: 40px 0;

        .sidebar-container {
            .amfaq_categories {
                .item {
                    font-size: 16px;
                    padding: 12px 0;
                    border-bottom: 1px solid #e4e4e4;
                    a {
                        color: #5b6057;
                    }

                    &.current {
                        a {
                            color: $christi;
                        }
                    }
                }
            }
        }
    }
}

.bluefoot-wrapper {
    margin-top: 20px;

    &.columns-2 {
        div[class*="bluefoot-column-"] {
            @include media('<=phone') {
                width: 100%;
                margin: 0;
            }
        }

        .bluefoot-column-4 {
            width: calc(100% * .33333 - 26.6668px);

            &:nth-child(n) {
                clear: none;
            }

            &:nth-child(2n) {
                margin-right: 0;
                float: right;
            }
        }

        .bluefoot-column-8 {
            width: calc(100% * .66667 - 13.333200000000001px);

            &:nth-child(2n+1) {
                clear: both;
            }

            &:nth-child(2n+1) {
                clear: both;
            }

            &:nth-child(n) {
                float: left;
                margin-right: 40px;
                clear: none;
            }
        }

        .bluefoot-column-9 {
            width: calc(100% * .75 - 10px);

            @include media('<=tablet') {
                width: calc(100% * 1 / 2 - 20px);
            }

            &:nth-child(2n+1) {
                clear: both;
            }

            &:nth-child(n) {
                float: left;
                margin-right: 40px;
                clear: none;
            }
        }

        .bluefoot-column-3 {
            width: calc(100% * .25 - 30px);

            @include media('<=tablet') {
                width: calc(100% * 1 / 2 - 20px);
            }

            &:nth-child(2n) {
                margin-right: 0;
                float: right;
            }

            &:nth-child(n) {
                float: left;
                clear: none;
            }
        }

        .bluefoot-column-6 {
            width: calc(100% * .5 - 20px);

            &:nth-child(n) {
                float: left;
                margin-right: 40px;
                clear: none;
            }

            &:nth-child(2n) {
                margin-right: 0;
                float: right;
            }

            &:nth-child(3n) {
                margin-right: 0;
                float: right;
            }
        }
    }

    &.columns-3 {
        .bluefoot-column-4 {
            width: calc(100% * .33333 - 26.6668px);

            &:nth-child(n) {
                float: left;
                margin-right: 40px;
            }

            &:nth-child(3n) {
                margin-right: 0;
                float: right;
            }

            &:nth-child(3n+1) {
                clear: both;
            }
        }

        .bluefoot-column-6 {
            width: calc(100% * .5 - 20px);

            &:nth-child(n) {
                float: left;
                margin-right: 40px;
                clear: none;
            }

            &:nth-child(3n) {
                margin-right: 0;
                float: right;
            }
        }
    }

    &.columns-4 {
        .bluefoot-column-3 {
            width: calc(100% * .25 - 30px);

            @include media('<=tablet') {
                width: calc(100% * 1 / 2 - 20px);

                &:nth-child(2n) {
                    margin-right: 0 !important;
                    float: right;
                }
            }

            &:nth-child(4n+1) {
                clear: both;
            }

            &:nth-child(n) {
                float: left;
                margin-right: 40px;
            }

            &:nth-child(4n) {
                float: right;
                margin-right: 0;
            }
        }

        .bluefoot-column-4 {
            width: calc(100% * .33333 - 26.6668px);

            &:nth-child(n) {
                float: left;
                margin-right: 40px;
                clear: none;
            }

            &:nth-child(4n) {
                margin-right: 0;
                float: right;
            }
        }

        .bluefoot-column-12 {
            width: calc(100% * 1 - 0px);
        }
    }
}

.bluefoot-column-12 {
    width: calc(100% * 1 / 1 - 0px);
}


.bluefoot-wrapper ul {
    padding: 0;
    list-style-type: none;
    margin-top: 20px;
}

.bluefoot-wrapper ol {
    padding-left: 20px;

    li {
        color: rgba($mineshaft, 0.65);
        @include fontWeightLight();
        @include font-size($custom-cms-page-size);
        position: relative;
    }
}

.bluefoot-wrapper ul li {
    color: rgba($mineshaft, 0.65);
    @include fontWeightLight();
    @include font-size($custom-cms-page-size);
    position: relative;
    padding-left: 40px;
}

.bluefoot-wrapper ul li:before {
    @extend %check;
    content: '';
    width: 16px;
    height: 16px;
    left: 0;
    top: 7px;
    position: absolute;
}

.bluefoot-wrapper.center {
    text-align: center;
}

.bluefoot-column p {
    @include fontWeightLight();
    @include font-size($custom-cms-page-size);

    a {
        color: $christi;
    }
}

.bluefoot-textarea ul:not(.checked) {
    list-style-type: disc;
    padding: 0 0 0 20px;

    li {
        padding: 0;

        &:before {
            content: '';
            display: none;
        }
    }
}

body.cms-page-view .center {
    text-align: center;
}

body.cms-page-view .green-heading {
    @extend %h3--primary;
    text-align: center;
}

body.cms-page-view .bluefoot-column-4 {
    .bluefoot-entity {
        margin-bottom: 0;
    }

    figure {
        padding: 0 8%;
    }

    h3 {
        margin-top: 46px;
        margin-bottom: 46px !important;
        padding: 0px 30px;
    }

    p {
        @include font-size($custom-cms-page-size);
    }

    @include media('<=phone') {
        padding: 30px 0;
    }
}

.with-media-background.center {
    background-color: $desert-storm;
    text-align: center;
    padding: 0px;

    .bluefoot-column-12 {
        @include container-size();
        padding: 0 8.5%;

        p {
            margin: 0;
            color: rgba(black, 0.65);
            @include fontWeightLight();
            @include font-size($custom-cms-page-size);
        }

        @include media('<=phone') {
            padding: 0px;
            text-align: left;
        }

        .bluefoot-video {
            float: left;
            margin: 20px 0px 0px 0px;
            padding: 0 8.5%;
            @include media('<=phone') {
                padding: 0;
            }
        }
    }
}

// IE fix
.cms-page-view .bluefoot-column.bluefoot-structural.bluefoot-column-4 {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin-right: 0;
    }

}

.cms-index-index .bluefoot-product-list.products-grid .item .actions {
    display: none;
}

.bestsellers-row h3 {
    color: $christi;
    font-size: 24px;
}

.title-button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bluefoot-buttons {
        margin-bottom: 0;
    }

    @include media('<=phone') {
        flex-direction: column;
    }

    button {
        margin-top: 35px;
        background: $white;
        border: 2px solid $christi;
        color: $christi;
        font-size: 17px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
        text-align: left;
        line-height: inherit;
        padding: 15px 60px 10px 25px;

        &:after {
            background: $white;
            background-image: url('../svg/arrow/chevron-green.svg');
            background-repeat: no-repeat;
            background-position: center;
        }

        &:hover {
            text-decoration: underline;
        }

        @include media('<=phone') {
            margin-top: 0;
        }
    }
}

@supports (-ms-ime-align: auto) {
    .title-button button {
        padding: 15px 60px 15px 25px;

        &:after {
            top: 13px;
        }
    }
}

.bluefoot-code {
    &.code .hljs {
        padding: 0;
        @include fontWeightLight();;
        color: $default-font-color !important;
        overflow: hidden;
    }

    .hljs {
        background: transparent;
        padding: 0;
        overflow: hidden;
    }

    .CookieDeclarationTable {
        table-layout: auto;
    }

    tr {
        table-layout: auto;
    }
}

body.cms-page-view .bluefoot-row.cookie-content {
    padding-bottom: 0;
}

body.cms-page-view .bluefoot-row.cookie-content--table {
    padding-top: 0;
}

[data-content-type="row"][data-appearance="contained"] {
    @include container-size();
}


