.category-block {
    height: 325px;
    margin-top: 20px;
    position: relative;
    z-index: 2;

    @include media('>=phone', '<desktop') {
        height: 210px;
    }

    @include media('>=large') {
        height: 415px;
    }

    @include media('<=phone') {
        margin-top: 0;
    }

    .category-image {
        overflow: hidden;
        height: 100%;
        position: relative;
        z-index: 2;
        border-radius: 3px;

        > a {
            display: block;
            height: inherit;
        }

        img {
            width: 100%;
            height: auto;
            border-radius: 3px;
            transform: scale(1);
            transition: transform 2.5s ease-in;
        }
    }

    .glow {
        background-color: $black;
        height: 40px;
        width: calc(100% - 40px);
        position: absolute;
        left: 20px;
        bottom: 0px;
        opacity: 0;
        border-radius: 3px;
        filter: blur(10px);
        transition: all 0.2s ease-in;
    }

    &:after {
        content: '';
        opacity: 0.45;
        background-image: linear-gradient(-180deg, transparent 0%, $black 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 120px;
        z-index: 3;
        border-radius: 3px;
    }

    .category-block__button {
        @extend %button-primary;
        position: absolute;
        bottom: 35px;
        left: 35px;
        z-index: 4;
        @include media("<=tablet") {
            bottom: 15px;
        }
    }

    &:hover {
        .glow {
            opacity: 1;
            bottom: -10px;
            transition: all 0.4s ease-out;
        }

        .category-image {
            img {
                transform: scale(1.05);
                transition: transform 5s ease-out;
            }
        }
    }
}

// mobile nav green
.mobile_usp {
    @include media('>phone') {
        display: none !important;
    }
}

@include media('<=phone') {
    .bluefoot-wrapper {
        margin: 15px 0 5px 0;

        .mobile_usp {
            margin-bottom: 15px;
            margin-top: -10px;
        }
    }

    .category-row {
        background: #63a70a;

        &:first-child {
            margin-top: 10px;

            .bluefoot-wrapper.columns-2 {
                margin-top: 15px;
            }
        }

        .bluefoot-wrapper.columns-2 {
            .bluefoot-column-6 {
                width: 100%;
            }
        }
    }

    .category-image {
        display: none;
    }

    .category-block {
        margin-bottom: 0px;
        z-index: 1;
        height: auto;

        &:after {
            background: none;
            left: unset;
            bottom: unset;
        }

        .category-block__button {
            position: relative;
            z-index: 4;
            height: 60px;
            background: white;
            left: 0;
            bottom: unset;
            width: 100%;
            position: relative;
            border-radius: 3px;
            color: #333333;
            font-size: 18px;
            font-weight: 200;
            text-decoration: none;
            margin-bottom: 10px;

            span {
                line-height: 60px;
                margin-left: 30px;

                @media screen and (max-width: 399px) {
                    font-size: 14px;
                }
            }

            &:before {
                content: '';
                display: block;
                height: 40px;
                width: 40px;
                left: 10px;
                top: 10px;
                position: absolute;

            }

            &:after {
                top: 15px;
                background: url('../svg/arrow/chevron-black.svg') no-repeat center;
            }
        }
    }

    .cat1 a:before {
        background: url('../svg/menu/batteries.svg') no-repeat center;
    }

    .cat2 a:before {
        background: url('../svg/powerbanks.svg') no-repeat center;

    }

    .cat3 a:before {
        background: url('../svg/flashlights.svg') no-repeat center;
    }

    .cat4 a:before {
        background: url('../svg/chargers.svg') no-repeat center;
    }

    .glow {
        display: none;
    }
}

