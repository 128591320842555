/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _product-list contains {less} for product lists
 *
 */

.#{$prefix}-product-list {
  li.item,
  .item li {
    list-style: none;
    margin-left: 0;
    .product-image img {
      max-width: 100%;
      height: auto;
    }
  }
  ul.add-to-links {
    padding-left: 0;
  }

  &.products-grid {
    margin: 0;
    padding: 0;
    width: auto;
    .item {
      .actions {
        display: block;
      }
    }
  }
}
