body.customer-account-create {
  .columns {
    padding: 60px 15px 0 15px;
  }

  .form.create.account {
    width: 70%;
    margin-bottom: 50px;
    @include media('<=phone') {
      width: 100%;
    }
  }

  .fieldset.create.account {
    .field > .label {
      &:after {
        margin-left: 7px;
      }
    }
  }

  .page-title{
    margin-left: 15px;
  }

  .fieldset > .legend {
    @extend %h3;
    @extend %h3--primary;
  }

  .label {
    cursor: pointer;
  }

  .fieldset > .field:not(.choice) > .label {
    @extend %label;
    @extend %label--small;
    line-height: 50px;
    padding: 0;
    text-align: left;
  }

  .field.choice.newsletter{
    display: flex;
    flex-direction: column;
  }

  .actions-toolbar {
    .primary button {
      @extend %button-primary;
      border: none;
    }
    .secondary {
      line-height: 50px;
      height: 50px;
    }
  }
  .column:not(.sidebar-additional) form .actions-toolbar{
    margin-left: 0;
  }
}
