.am_shopby_filter_items_attr_product_color .swatch-option{
    margin: 0 20px 5px 0;
}

.swatch-option.color,
.swatch-option.image {
    border-radius: 10px;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    &.selected,
    &:not(.disabled):hover {
        border: none;
        outline: none;
        margin-left: 0;
    }
}

.swatch-option.image{
    border: none;
}

.filter-options .filter-options-content .attribute-options--visual .item {
    @extend %p;
    @extend %p--light;
    display: block;
    margin: 5px 0 !important; //hard overwrite default
    position: relative;
    &.selected > div:after {
        content: '';
        left: 4px;
        height: 9px;
        position: absolute;
        top: 5px;
        width: 12px;
        z-index: 100;
    }
    &.selected .check-white:after {
        @extend %filter-check-white;
    }
    &.selected .check-black:after {
        @extend %filter-check-black;
    }
}

.swatch-option.image.selected{
    position: relative;
    &:after{
        @extend %filter-check-black;
        content: '';
        left: 4px;
        height: 9px;
        position: absolute;
        top: 5px;
        width: 12px;
        z-index: 100;
    }
}