.page-layout-2columns-right .columns {
  @include container-size();
}

.page-layout-2columns-right .column.main {
    width: calc(100% * 2/3 - 13.333333333333332px);

    &:nth-child(n) {
        float: left;
        margin-right: 40px;
        clear: none;
    }

    &:last-child {
        margin-right: 0;
    }
}

.page-layout-2columns-right .sidebar-container {
    width: calc(100% * 1/3 - 26.666666666666664px);

    &:nth-child(3n+1) {
        clear: both;
    }

    &:nth-child(n) {
        float: left;
        margin-right: 40px;
        clear: none;
    }

    @include media('<=phone') {
        width: calc(100% * 1/1 - 0px);

        &:nth-child(n) {
            margin-right: 0;
            float: right;
        }

        &:nth-child(n+1) {
            clear: both;
        }
    }
}

.page-layout-2columns-left .column.main {
    width: calc(100% * 2/3 - 13.333333333333332px);

    &:nth-child(n) {
        float: left;
        margin-right: 40px;
        clear: none;
    }

    &:last-child {
        margin-right: 0;
    }
}

.page-layout-2columns-left .sidebar-container {
    width: calc(100% * 1/3 - 26.666666666666664px);

    &:nth-child(3n+1) {
        clear: both;
    }

    &:nth-child(n) {
        float: left;
        margin-right: 40px;
        clear: none;
    }

    @include media('<=phone') {
        width: calc(100% * 1/1 - 0px);

        &:nth-child(n) {
            margin-right: 0;
            float: right;
        }

        &:nth-child(n+1) {
            clear: both;
        }
    }
}

body.catalog-category-view.page-layout-2columns-left,
body.catalogsearch-result-index.page-layout-2columns-left {
  .column.main {
      width: calc(100% * 3/4 - 5px);
      padding-left: 20px;

      &:nth-child(2n) {
          margin-right: 0;
          float: right;
      }

    @include media('<=tablet') {
        width: calc(100% * 1/1 - 0px);
        padding-left: 0;
    }
  }

  .ghost-container {
    @include container-size();
    height: 100%;

    .ghost {
        width: calc(100% * 3/4 - 5px);
        margin-left: calc(100% * 0.25 - 15px + 20px) !important;
        background-color: $desert-storm;
        height: 100%;
    }
  }

  .sidebar-container {
      width: calc(100% * 1/4 - 15px);

      &:nth-child(n) {
          float: left;
          margin-right: 20px;
      }

      &:nth-child(2n+1) {
          clear: both;
      }

    @include media('<=tablet') {
        width: calc(100% * 1/1 - 0px);

        &:nth-child(n) {
            float: left;
            margin-right: 0;
        }
    }
  }
}

.form-create-account {
  .fieldset-fullname {
    .fields {
      .field {
        @media screen and (min-width: 768px) {
          float: none;
        }
      }
    }
  }

  .field {
    &.fullname {
      margin-bottom: 10px;
    }
  }

  select {
    border: 1px solid rgba($gainsboro, 0.5);
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    border-radius: 3px;
    @include fontWeightLight();
    font-size: 15px;
    min-width: 100px;
  }
}

.main-usps-mobile {
  display: none;

  @media screen and (max-width: 991px) {
    display: block;

    .header__usps--wrapper {
      display: block !important;
      border-top: none !important;
      padding-bottom: 0;

      > .header__usps {
        display: block !important;
        padding-bottom: 0;

        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            width: 43%;

            @media screen and (max-width: 767px) {
              width: 75%;
            }

            @include media('<=tablet', ">phone") {
              lost-column: 0.5 40px;
              margin: 0;
              padding-left: 30px;
              &:nth-child(even){
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    .bluefoot-html {
      margin-bottom: 0;
    }
  }
}

.page-main{
  position: relative; //category page header image
  @include media("<=phone"){
    margin: 0 15px;
  }
}

@include media('>phone'){
  .cms-index-index .products-grid .swiper-button-next,
  .cms-index-index .products-grid .swiper-button-prev{
    display: none;
  }
}

.swiper-container ol.swiper-wrapper{
  padding: 0;
  lost-utility: clearfix;
}





@include media('<=phone') {
  .bluefoot-product-list.products-grid{
    margin: 0 15px;
    overflow: hidden;
  }

  .swiper-button-next,
  .swiper-button-prev{
    top: 42%;
  }

  .swiper-button-prev{
    left: 20px;
  }
}

@include media('<=tablet'){
  .cms-index-index .bluefoot-column-12{
    lost-column: 1/1
  }
}

.bluefoot-wrapper {
    &:before,
    &:after {
        content: "";
        display: table;
    }
}

