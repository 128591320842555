.sidebar .block.block-compare{
  display: none;
}

body.catalog-product-compare-index {
  a.product-item-photo {
    .product-image-container {
      width: 100%;
      .product-image-wrapper {
        height: auto;
        overflow: visible;
        padding: 20px 0 0;
        .product-image-photo {
          width: 140px;
          bottom: auto;
          display: block;
          height: auto;
          left: auto;
          margin: auto;
          max-width: 100%;
          position: static;
          right: auto;
          top: auto;
        }
      }
    }
  }
  .table-wrapper.comparison {
    lost-offset: 1/4;
    background-color: $desert-storm;
    border-radius: 4px;
    box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.2);
    padding: 52px 20px 60px 40px;
    @include media("<=tablet"){
      lost-offset: 0
    }
    .cell {
      padding: 13px;
      padding-top: 0;
      position: relative;
      a.action.delete {
        position: absolute;
        right: 0px;
        top: 10px;
        z-index: 1;
        &:before{
          color: $error-content;
        }
      }
    }
  }
  .table:not(.cart):not(.totals) > thead > tr > th,
  .table:not(.cart):not(.totals) > thead > tr > td {
    border-bottom: none;
  }

  .table-comparison .cell.label .attribute.label,
  .table-comparison .cell .attribute.value {
    color: #000000;
    font-size: 18px;
    @include fontWeightLight();
  }

  .table-comparison .cell.product.info,
  .table-comparison .cell.product.label {
    border-bottom: none;
  }

  table {
    margin-bottom: 0;
    tr.middle-row,
    tr.bottom-row,
    tr.button-row {
      td {
        background-color: $white;
        text-align: center;
        box-shadow: 0 0 25px 0 rgba($black, 0.05);
        &.cell.product.attribute,
        &.info {
          position: relative;
          &:before {
            display: block;
            color: black;
            width: 100%;
            height: 15px;
            position: absolute;
            background-color: white;
            content: '';
            left: 0;
            top: -15px;
          }
        }
        .actions-primary {
          display: block;
        }
        .action.tocart.primary {
          @extend %button-primary;
          border: none;
          width: 100%;
          text-align: left;
        }
        &.spacer {
          width: 20px;
          background-color: $desert-storm;
          border-top: none;
          box-shadow: none;
        }
      }
      .border-top {
        height: 1px;
        width: 100%;
        background-color: $mercury;
        margin: 0 auto;
        margin-bottom: 13px;
      }
    }
  }
}

table[class*='table-comparison--items'] {
  button {
    &:before {
      background: url('~theme-assets/svg/cart-white.svg') no-repeat center;
      content: '';
      display: block;
      width: 20px;
      height: 50px;
      margin: 0 15px;
    }
    span {
      display: none;
    }
    &:after {
      display: none;
    }
  }
}
