/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _fonts declares fonts used in the BlueFoot
 * The '#{$prefix}-icons' font is generated using Icomoon <https://icomoon.io/>
 * It contains icons from Googles material design icon set <https://github.com/google/material-design-icons>
 *
 */

/*-- Icon Font --*/
@font-face {
  font-family: '#{$prefix}-icons';
  src: url('~theme-default/web/fonts/#{$prefix}/#{$prefix}.eot');
  src: url('~theme-default/web/fonts/#{$prefix}/#{$prefix}.eot?#iefix')
      format('embedded-opentype'),
    url('~theme-default/web/fonts/#{$prefix}/#{$prefix}.woff') format('woff'),
    url('~theme-default/web/fonts/#{$prefix}/#{$prefix}.ttf') format('truetype'),
    url('~theme-default/web/fonts/#{$prefix}/#{$prefix}.svg##{$prefix}')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
