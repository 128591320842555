.bluefoot-image {
  position: relative;
  z-index: 2;
  img {
    position: relative;
    z-index: 1;
  }
  .glow {
    background-color: $black;
    height: 40px;
    width: calc(100% - 40px);
    position: absolute;
    left: 20px;
    opacity: 1;
    bottom: -10px;
    border-radius: 3px;
    filter: blur(10px);
  }
}
