.catalog-category-view {
  .column.main {
    .category-description {
      @extend %p;
      clear: both;
      color: rgba($cod-gray, .50);
      h1 {
        font-size: 40px;
      }
      h2 {
        color: $black;
      }
      p {
        @extend %p;
        color: rgba($cod-gray, .50);
      }
      &[aria-expanded="false"] {
        position: relative;
        &:after {
          height: 100px;
          position: absolute;
          bottom: -1px;
          width: 100%;
          display: block;
          content: '';
          background-image: linear-gradient(
            -180deg,
            rgba(248, 248, 248, 0.00) 1%,
            #f8f8f8 100%
          );
        }
      }
    }
    a[data-readmore-toggle] {
      @extend %button-ghost-primary;
      @extend %button-ghost-primary--small;
      color: rgba($cod-gray, .50);
      border-color: rgba($cod-gray, .50);
      margin-bottom: 20px;
      &:hover {
        background-color: rgba($cod-gray, .50);
      }
    }
  }
}
