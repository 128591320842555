%chevron-white-right {
  background: url('../svg/arrow/chevron-white.svg') no-repeat center;
}

%chevron-gray-down {
  background: url('../svg/arrow/chevron-gray-down.svg') no-repeat
    center;
}

%chevron-green-right {
  background: url('../svg/arrow/chevron-green.svg') no-repeat center;
}

%chevron-black-right {
  background: url('../svg/arrow/chevron-black.svg') no-repeat center;
}

%chevron-yellow-right {
  background: url('../svg/arrow/chevron-yellow.svg') no-repeat center;
}

%chevron-gray-right {
  background: url('../svg/arrow/chevron-gray.svg') no-repeat center;
}
%chevron-gray-left {
  background: url('../svg/arrow/chevron-gray-left.svg') no-repeat
    center;
}

%chevron-breadcrumb {
  background: url('../svg/arrow/chevron-breadcrumb.svg') no-repeat
    center;
}

%download-gray {
  background: url('../svg/download.svg') no-repeat center;
}

%twitter {
  background: url('../svg/social/twitter.svg') no-repeat center;
}

%twitter-white {
  background: url('../svg/social/twitter-white.svg') no-repeat center;
}

%facebook {
  background: url('../svg/social/facebook.svg') no-repeat center;
}

%facebook-white {
  background: url('../svg/social/facebook-white.svg') no-repeat
    center;
}

%chat {
  background: url('../svg/contact/16-chat--dark.svg') no-repeat
    center;
}

%mail {
  background: url('../svg/contact/16-mail--dark.svg') no-repeat
    center;
}

%phone {
  background: url('../svg/contact/16-phone--dark.svg') no-repeat
    center;
}

%search {
  background: url('../svg/zoom.svg') no-repeat center;
}

%search--black {
  background: url('../svg/zoom--black.svg') no-repeat center;
}

%house {
  background: url('../svg/house.svg') no-repeat center;
}

%house-green {
  background: url('../svg/house-green.svg') no-repeat center;
}

%delete {
  background: url('../svg/delete.svg') no-repeat center;
}

%check {
  background: url('../svg/check.svg') no-repeat center;
}

%check-white {
  background: url('../svg/check-white.svg') no-repeat center;
}

%filter-check-white {
  background: url('../svg/filter-check-white.svg') no-repeat center;
}

%filter-check-black {
  background: url('../svg/filter-check-black.svg') no-repeat center;
}

%filter-funnel {
  background: url('../svg/filter.svg') no-repeat center;
}

%kiyoh {
  background: url('../images/kiyoh.png') no-repeat center;
}

%filter-plus {
  background: url('../svg/filter-plus.svg') no-repeat center;
}

%filter-min {
  background: url('../svg/filter-min.svg') no-repeat center;
}

.sorter-action:before{
  margin-top: 0;
}
