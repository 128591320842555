/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _row contains {less} for rows
 *
 */

.#{$prefix}-row {
  width: 100%;
  box-sizing: border-box;

  &.with-media-background,
  &.bluefoot-wrapper-row {
  }

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

.col-main {
  .#{$prefix}-row {
    > .#{$prefix}-entity {
      padding-left: $bf-margin/2;
      padding-right: $bf-margin/2;
      &.no-padding {
        padding-left: 0;
        padding-right: 0;
        box-sizing: border-box;
      }
    }
  }
}
