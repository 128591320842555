body.cms-page-view.page-layout-1column .custom-template-gp  {
    padding: 0;

    &:not(.header) {
        margin-bottom: 50px;

        @media screen and (max-width: 767px) {
            margin-bottom: 25px;
        }
    }

    &.header + div {
        margin-bottom: 0;
    }

    .bluefoot-column-12 {
        @media screen and (min-width: 768px) and (max-width: 991px) {
            width: 100%;
        }
    }

    div[class*=columns-]:not(.columns-1) {

        div[class*=bluefoot-column-]:not(.bluefoot-column-12) {
            position: relative;
            padding-bottom: 50px;

            @media screen and (max-width: 767px) {
                width: 100%;
                margin-right: 0;
                padding-top: 0;

                + div[class*=bluefoot-column-] {
                    margin-top: 30px;
                }
            }

            .bluefoot-buttons {
                position: absolute;
                bottom: 0;
                margin-bottom: 0;
                width: 100%;

                button {
                    margin-bottom: 0;
                }
            }
        }
    }

    h2, h3, h4, h5, h6 {
        font-weight: bold;
        font-family: 'MyriadPro';

        @media screen and (max-width: 767px) {
            margin-top: 0 !important;
            margin-bottom: 20px !important;
        }
    }

    .bluefoot-wrapper {
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: 767px) {
            padding: 0 15px;
        }
    }

    &.header {
        .bluefoot-wrapper {
            margin-top: 0;
        }
    }

    .bluefoot-entity {
        margin-bottom: 30px;
    }

    .bluefoot-slide {
        margin-bottom: 30px;
        width: 100%;

        @media screen and (max-width: 991px) {
            margin-bottom: 0;
        }

        .bluefoot-slide-content {
            width: 100%;
            align-items: flex-start;

            @media screen and (max-width: 767px) {
                display: flex;
                width: 100%;
            }
        }
    }

    figure, h3 {
        padding: 0;
    }

    button {
        @extend %button-primary;

        @media screen and (min-width: 768px) and (max-width: 991px) {
            padding-left: 15px;
            padding-right: 40px;
        }
    }

    &.white-button button {
        @extend %button-primary;
        border: 2px solid transparent;

        @media screen and (min-width: 768px) and (max-width: 991px) {
            padding-left: 15px;
            padding-right: 45px;
        }

        &:not(:hover) {
            background-color: white;
            background-image: none;
            border: 2px solid #63a70a;
            color: #63a70a;
        }
    }

    .category_banner {
        background-size: cover;
        min-height: 240px;
    }

    .category_banner--wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 240px;

        h2 {
            color: $white;
            position: relative;
            padding-left: 0;
            width: 34%;

            @include media("<=phone") {
              left: 15px;
              padding-right: 15px;
              width: calc(100% - 15px);
            }
            &:before {
              background-color: $white;
            }
          }

          svg {
              display: none;
          }

          a {
            @extend %button-primary;

            margin-left: 0;

            &:hover {
                text-decoration: none;
            }

            @include media("<=phone") {
                margin-left: 15px;
            }
          }
    }
}

body {
    &.cms-index-index {
        .bluefoot-row {
            .bluefoot-wrapper {
                overflow: hidden;
            }

            .bluefoot-column {
                &-6 {
                    width: calc(100% * .5 - 20px);

                    &:nth-child(2n+1) {
                        clear: both;
                    }

                    &:nth-child(n) {
                        float: left;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}

//body.cms-index-index #maincontent{
//    padding-top: 107px;
//}
