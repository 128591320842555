.minicart-wrapper {
    @include media('<=tablet') {
        margin: 0;
        right: 89px;
        top: 0;
        z-index: 9;
    }

    .product .actions {
        display: none;
    }

    .product.options {
        color: rgba($cod-gray, 0.5);

        .content {
            dl {
                @include clearfix();

                dt {
                    float: left;
                    margin-right: 5px;

                    &:after {
                        content: ':';
                    }
                }
            }
        }
    }

    .block-minicart {
        border: 1px solid $gainsboro;
        border-radius: 3px;
        box-shadow: none;
        margin-top: 10px;
        padding: 0;
        width: 560px;
        z-index: 120 !important;
        @include media('<=phone') {
            border-radius: 0;
            margin-top: 0;
            right: -89px;
            width: 100vw;
            &:before {
                right: 102px;
            }
            &:after {
                right: 101px;
            }
        }

        .block-title {
            display: block;

            span.text {
                @extend %h3;
                @extend %h3--primary;
                border-bottom: 1px solid $concrete;
                display: block;
                margin: 0 20px 15px 20px;
                padding: 15px 0;

                @include media('<=tablet') {
                    padding: 15px 10px;
                }
            }

            span.qty {
                display: none;
            }
        }

        .checkout {
            padding: 14px 57px 14px 17px !important;
        }

        .actions {
            .secondary {
                .viewcart {
                    background: #f2f2f2;
                    color: #000;
                    text-shadow: none;
                    border: 1px solid #cdcdcd;
                }
            }
        }

        .block-content > .actions > .primary {
            margin: 0;
        }

        #minicart-content-wrapper {
            position: relative;
            z-index: 2;

            .block-content {
                padding: 0 25px 25px 25px;
                @include media('<=tablet') {
                    padding: 0 20px 20px 20px;
                }
                background-color: $white;
                z-index: 100;
                position: relative;

                .minicart-items-wrapper {
                    border: none;
                    margin-bottom: -40px;
                    @include media('<=tablet') {
                        margin-bottom: 0;
                        max-height: 30vh;
                    }
                }
            }

            &:after {
                content: '';
                height: 80px;
                width: 520px;
                position: absolute;
                opacity: 0.2;
                background: $black;
                border-radius: 3px;
                filter: blur(10px);
                bottom: -10px;
                left: 20px;
                z-index: 1;
            }
        }
    }

    .minicart-items {
        .product-item-name {
            margin: 0;

            a {
                font-size: 2rem;
            }
        }

        .product-item {
            border: none;
        }

        .update-cart-item {
            border: none;
            margin: 15px 0 0 10px;
            position: inherit;
        }

        .item-qty {
            margin-right: inherit;
            text-align: center;
            width: 60px;
        }
    }

    .secondary {
        display: inline;
        float: left;
        margin-right: 5px;
    }

    a.action.edit,
    a.action.viewcart {
        //display: none;
    }

    .minicart-items .product-item-details {
        padding-left: 120px;

        .price-including-tax,
        .price-excluding-tax {
            margin: 0;
        }

        .price {
            font-weight: 500;
        }

        .product-title-box {
            display: inline-block;
            float: left;
            line-height: 26px;
            width: 300px;
            @include media('<=tablet') {
                float: none;
                width: auto;
            }
        }

        a.ui-spinner-button {
            margin-left: 0;
        }

        a {
            @extend %p;
            @extend %p--intro;
            display: block;
            line-height: 26px;
            margin: 0;

            &:hover {
                color: $lima;
                text-decoration: none;
            }

            .ui-icon {
                display: none;
            }
        }

        .details-qty {
            margin-top: 0;

            .ui-spinner {
                float: inherit;
                margin-top: 10px;
                top: 0;
                width: 120px;

                &-down {
                    left: 10px;
                }
            }
        }
    }

    .price-container {
        float: right;
        @include media('<=tablet') {
            float: none;
        }

        .price-wrapper {
            .minicart-price span.price {
                font-size: 18px;
                color: $christi;
                @include fontWeightBold();
                margin-right: 15px;
            }
        }
    }

    .subtotal .price-container {
        float: none;
    }

    .action.showcart {
        margin: 10px 0;
        background-color: $white;
        border-radius: 20px;
        height: 40px;
        position: relative;
        width: 40px;

        &.active:before,
        &:before {
            background: url('../svg/cart.svg') no-repeat;
            font-family: inherit;
            content: '';
            height: 16px;
            margin: 14px 11px;
            width: 16px;
        }

        .counter.qty {
            background: $gold;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            color: $black;
            font-size: 14px;
            @include fontWeightBold();
            left: 32px;
            line-height: 20px;
            margin: 0;
            position: absolute;
            top: 1px;
        }
    }

    .items-total {
        display: none;
    }

    .subtotal {
        background-color: $desert-storm;
        margin: 0;
        padding: 15px 10px;
        color: $christi;
        font-size: 18px;
        @include fontWeightBold();

        span.label {
            float: left;
        }
    }

    .actions {
        @include clearfix();
        text-align: left;

        .primary,
        .goback {
            display: inline-block;
        }

        .secondary {
            float: right;
            margin-bottom: 0;
        }

        .goback {
            float: left;
            @include media('<=tablet') {
                display: none;
            }

            button {
                background: transparent;
                border: none;
                color: $aluminum;
                font-size: 18px;
                @include fontWeightBold();
                line-height: 50px;
                padding: 0;
                text-align: left;

                &:before {
                    content: '';
                    display: inline-block;
                    margin-right: 10px;
                    width: 12px;
                    height: 12px;
                    @extend %chevron-gray-left;
                }
            }
        }
    }

    .action.viewcart {
        @extend %button-primary;
        border: none;
        margin-bottom: 0;
        text-align: left;

        &:hover {
            text-decoration: none;
        }
    }
}


.product-item-pricing {
    height: 35px;
}

.minicart-items .action.delete:before {
    content: '';
    @extend %delete;
    width: 16px;
    height: 16px;
}

.details-qty select {
    margin: 5px 0;
    border: 1px solid #dddddd;
    height: 40px;
    padding: 6px 5px 5px;
    width: 60px;
    line-height: 50px;
}


