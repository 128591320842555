section.sos-box {
  border: 1px solid #dddddd;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  bottom: 0;
  position: absolute;
  body.cms-page-view & {
    position: relative;
    border: 1px solid $gainsboro;
    margin-bottom: 20px;
  }
  width: 100%;
  div.sos-box--inside {
    background-color: $white;
    border-top-right-radius: 10px;
    display: block;
    height: 100%;
    padding: 30px 30px 22px 30px;
    position: relative;
    z-index: 2;

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
      padding: 30px 15px 22px 15px;
    }

    a.faq {
      width: 100%;

      @media screen and (min-width: 1024px) and (max-width: 1199px) {
        padding: 0 0 0 15px;
      }
    }
  }

  .product-info-main & {
    position: relative;
    z-index: 12;
    margin-top: 60px;
    @include media("<=phone"){
      display:none;

      body[class*="catalog-product-view"] & {
        display: block;
      }
    }
  }

  .bluefoot-wrapper &,
  .product-info-main & {
    a.faq {
      @extend %button-ghost-primary;
      @extend %button-ghost-primary--more;
      margin-top: 20px;
    }
  }

  span.sos-member {
    &--anouk {
      background: url('../images/anouk.png') no-repeat center;
      background-size: 81px 120px;
      height: 120px;
      position: absolute;
      left: -80px;
      top: -30px;
      width: 81px;
      z-index: 9;
    }

    transform: scaleX(-1);
    position: absolute;
    left: 0;
    top: 0;

    &:before,
    &:after {
      content: '';
      height: 0;
      position: absolute;
      right: -1px;
      top: -1px;
      width: 0;
    }

    &:after {
      border-left: 100px solid transparent;
      border-top: 100px solid $christi;
      border-top-right-radius: 3px;
    }

    &:before {
      border-right: 100px solid transparent;
      border-bottom: 100px solid $white;
      z-index: 10;
    }
  }
  h5 {
    @extend %h3;
    @extend %h3--primary;
    margin-bottom: 20px;
    position: relative;
    z-index: 100;
    .customerservice-menu & {
      margin: 5px 0;
    }
  }
  h6 {
    @extend %h5;
    @extend %h5--50;
    margin: 5px 0 25px 0;
    z-index: 15;
    position: relative;
    .page-footer & {
      display: none;
    }
  }
  p {
    @extend %p;
    .customerservice-menu & {
      margin: 20px 0;
    }
    a {
      @extend %p;
      position: relative;
      z-index: 100;
      &:before {
        background-size: 16px;
        content: '';
        display: inline-block;
        height: 16px;
        margin-right: 16px;
        position: relative;
        top: 2px;
        width: 16px;
      }
      &:hover {
        text-decoration: none;
      }
    }
    &.phone a:before {
      @extend %phone;
    }

    &.mail a:before {
      @extend %mail;
    }

    &.chat a:before {
      @extend %chat;
    }
    span {
      display: block;
      margin-left: 33px;
      color: rgba($cod-gray, 0.5);
      &:nth-child(2) {
        .customerservice-menu &,
        .page-footer & {
          display: none;
        }
      }
    }
  }
  .faq {
    .page-footer &,
    .customerservice-menu & {
      display: none;
    }
  }

  .panel .customerservice-menu & {
    @include media('<=tablet') {
      left: -20px;
    }
    border: 1px solid $gainsboro;
    height: 296px;
    right: 0;
    margin-top: 13px;
    position: absolute;
    top: 100%;
    width: 380px;
    z-index: 10;

    &:before,
    &:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0;
      right: auto;
    }
    &:before {
      border: 9px solid;
      border-color: transparent transparent $white transparent;
      right: 20px;
      top: -18px;
      z-index: 20;
      @include media('<=tablet') {
        left: 40px;
      }
    }
    &:after {
      border: 10px solid;
      border-color: transparent transparent $gainsboro transparent;
      right: 19px;
      top: -20px;
      z-index: 19;
      @include media('<=tablet') {
        left: 39px;
      }
    }

    .sosbox {
      height: 275px;
    }
    div.sos-box--inside {
      padding: 20px 30px 0 110px;
    }

    span.sos-member:after {
      border-top: 100px solid $gainsboro;
    }
  }

  body.cms-no-route .bluefoot-wrapper .customerservice-menu & {
    &:after {
      @extend %gaussian-shadow;
    }

    border: none;
    height: inherit;
    margin-top: 0;
    position: relative;
    top: 0;
    width: 100%;

    h5 {
      margin: 5px 0 20px 0;
    }

    h6 {
      display: none;
    }

    p {
      margin: 10px 0;
    }

    &:before,
    &:after {
      border: none;
    }

    span.sos-member:after {
      border-top: 100px solid $christi;
    }
  }
}

.footer-columns--wrapper {
  .sos-box-container {
      width: calc(100% * 1/4 - 30px);

      &:nth-child(n) {
          float: left;
      }

      &:last-child {
          margin-right: 0;
      }

    @include media('>=tablet') {
      margin-top: -80px;
    }

    @include media('<tablet') {
        width: calc(100% * 1/2 - 20px);
      lost-offset: 1/2;
    }

    @include media('<phone') {
      display: none;
    }
  }
}

body[class^="checkout"], body[class*=' checkout'] {
  .sos-box-wrapper {
    .sos-member{
      display: none;
    }
    section.sos-box{
      left: inherit;
      right: -15px;

      &:before,
      &:after{
        left: inherit;
      }
      &:before{
        right: 20px;
      }
      &:after{
        right: 19px;
      }
    }
  }
}

.kiyoh-box{
  background: white;
  overflow: hidden;
  padding-left: 18px;
  border: 1px solid #dddddd;
  border-top: 0;
  width: calc(100% + 2px);
  position: relative;
  left: -1px;
  z-index: 3;
  height: 100px;

  .sos-box-wrapper & {
    top: -30px;
  }

  footer & {
    height: 70px;
  }

  iframe {
    position: absolute;
    top: 7px;
    left: 23px;

    footer & {
      top: 0;
    }
  }
}

.footer.content{
  section.sos-box div.sos-box--inside{
    padding-bottom: 0;
  }
}
