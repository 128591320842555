.yellow-box {
  @extend %secondary-gradient;
  border-radius: 3px 3px 0 0;
  padding: 30px;
  h3 {
    @extend %h3;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      @extend %usp-list;
      line-height: 40px;
      margin: 0;
      padding: 0 0 0 26px;
      position: relative;
      &:before {
        @extend %check-white;
        content: '';
        display: block;
        height: 16px;
        left: 0;
        position: absolute;
        top: 10px;
        width: 16px;
      }
    }
  }
}

@include media('<=phone'){
  .catalog-product-view .yellow-box{
    display: none
  }
}
