[data-content-type="column-line"] {
    @include media('>tablet') {
        margin: 1rem 0;
    }

    @include media('<=phone') {
        display: block !important;
    }

    @include media('>tablet') {
        margin: 2rem -1rem;
    }

    [data-content-type="column"] {
        padding: 0 1rem;

        @include media('<=tablet') {
            padding: 1rem;
            width: 100% !important;
        }
    }
}
