/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _map contains {less} for Map blocks
 *
 */

.#{$prefix}-map-container {
  width: 100%;
  height: 300px;
}
.#{$prefix}-map {
  max-width: 100%;
  width: 100%;
  height: 100%;
}
