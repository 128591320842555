/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _slider contains {less} for carousel blocks and carousel slide items
 * The carousel uses the jQuery plugin Slick <http://kenwheeler.github.io/slick/>
 *
 */

.#{$prefix}-slider {
    float: left;
    width: 100%;
    background: url('../web/images/loading_36x36.gif') center $bf-margin no-repeat;
    min-height: ($bf-margin*2) + 36px;

    &.bluefoot-slider-with-dots {
        margin-bottom: 50px;
    }

    &.slick-visible {
        background: none;
        min-height: 0;

        .bluefoot-advanced-slide,
        .bluefoot-slide,
        .slick-arrow,
        .slick-dots {
            opacity: 1;
            visibility: visible;
        }
    }

    img {
        max-width: 100%;
        margin: 0 auto;
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        width: $common_height;
        height: $common_height;
        line-height: $common_height;
        margin-top: -($common_height/2);
        border: none;
        z-index: 10;
        padding: 0;
        @extend %#{$prefix}-icons;
        font-size: $regular_font_size;
        @extend %transition;
        opacity: 0;

        &:focus {
            outline: none;
        }

        &.slick-disabled {
        }

        span {
            width: $common_height;
            padding: 0;
        }
    }

    .slick-next {
        right: 0;
    }

    .slick-prev {
        left: 0;
    }

    .slick-dots {
        list-style: none;
        @extend %transition;
        opacity: 0;
        padding: 0;
        text-align: center;
        margin: $bf-margin 0;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -50px;

        li {
            display: inline-block;
            margin: 0 $bf-margin/2;

            button {
                text-indent: -9999px;
                height: $small_height;
                line-height: $small_height;
                width: $small_height !important;
                padding: 0 !important;
                border-radius: 50%;
                border: none;
                background: $theme_color_2;

                &:focus {
                    outline: none;
                }

                &:hover {
                    background: $theme_color_1;
                }
            }

            &.slick-active button {
                background: $theme_color_1;
            }
        }
    }
}

.bluefoot-advanced-slide,
.bluefoot-slide {
    margin-bottom: 0;
    @extend %transition;

    &.hidden-on-load {
        display: none;
    }
}

/* Advanced Slider */
.bluefoot-advanced-slide {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &.bluefoot-slide-with-link {
        cursor: pointer;
    }

    .bluefoot-advanced-slide-content {
        margin: $bf-margin*2;
        padding: $bf-margin*2;
        display: inline-block;
        width: 40%;
        text-align: center;

        &.bluefoot-with-overlay {
            background: rgba(0, 0, 0, 0.9);

            h2,
            h3,
            h4,
            p,
            li {
                color: white;
            }
        }
    }
}

// M2 flexbox fix

.columns .column.main {
    max-width: 100%;
}

@include media(">phone", "<=tablet") {
    .bluefoot-advanced-slide {
        text-align: center;

        .bluefoot-wrapper {
            padding: 0;
        }

        .bluefoot-advanced-slide-content {
            width: 60%;
            margin-left: 20%;
            margin-right: 20%;
            box-sizing: border-box;
        }
    }
}

@include media("<=phone") {
    .bluefoot-advanced-slide {
        .bluefoot-advanced-slide-content {
            width: 80%;
            margin-left: 10%;
            margin-right: 10%;
            padding: $bf-margin;
        }
    }
}
