.block-search {
  margin: 5px;
  padding: 0;
  width: 500px;
  @include media('<=desktop') {
    width: 350px;
  }
  //@include media('<=tablet', '>phone') {
  //  margin: 15px 0;
  //  position: absolute;
  //  right: 15px;
  //  top: 70px;
  //  width: 350px;
  //}
  .control {
    border: none;
  }
  @include media('>=tablet') {
    .block.block-content {
      background: $white;
      border-radius: 25px;
      display: inline-block;
      color: $cod-gray;
      font-size: 15px;
      height: 50px;
      line-height: 50px;
      padding: 0 25px;
      width: 100%;
      .field .control {
        padding: 0;
        input {
          border: none;
          background-color: transparent;
          margin: 0;
          padding: 0 35px 0 0;
          font-style: normal;
        }
      }
    }
  }

  @include media('<tablet') {
    .block.block-content .field .control input {
      border: none;
      border-radius: 40px;
      height: 50px;
      line-height: 50px;
      margin: 0;
      padding: 0 35px;
    }
  }

  @include media('<=tablet') {
    .block.block-content {
      //      position: static;
      border-radius: 30px;
      //      top: 10px;
      //      right: 140px;
    }
  }
  .action.search:before {
    @extend %search;
    font-family: inherit;
    content: '';
    height: 16px;
    position: relative;
    right: 15px;
    top: 13px;
    width: 16px;
  }

  .label {
    display: none;
  }

  @include media('<tablet') {
    .control {
      background: $christi;
      height: 60px;
      opacity: 0;
      position: absolute !important;
      right: 0;
      top: 60px;
      width: 100%;
      margin: 0;
      padding: 0 10px 0 10px;
      transition: opacity 0.2s ease-in;
      transition-delay: 0.1s;

      .show-search & {
        right: 0;
        opacity: 1;
      }

      input {
        display: block;
        left: 0;
        padding: 0 20px;
        position: relative;
        top: 0;
        width: 100%;
        z-index: 100;
      }
    }
    .label {
      height: 40px;
      width: 40px;
      background-color: $white;
      border-radius: 40px;
      cursor: pointer;
      display: block;
      position: absolute;
      top: 10px;
      right: 140px;

      &:before {
        background: url('../svg/zoom--black.svg') no-repeat center;
        font-family: inherit;
        content: '';
        height: 16px;
        position: relative;
        right: -12px;
        top: 11px;
        width: 16px;
      }
      @supports (-ms-ime-align: auto) {
        &:before {
          top: 10px;
        }
      }
    }
  }

  @include media('<=phone') {
    .control {
      padding: 0px 10px;
    }
  }
}

@include media('>tablet') {
  .block-search .action.search {
    display: inline-block;
    background-image: none;
    background: none;
    box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    padding: 5px 0;
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 1;

    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.block-search .action.search.disabled,
.block-search .action.search[disabled],
fieldset[disabled] .block-search .action.search {
  //opacity: 1;
}

@include media('<=tablet'){
  .block-search .block.block-content .field .control input{
    z-index: 100;
    position: relative;
  }
}
