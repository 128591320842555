@mixin font-size($breakpoints) {
  @each $breakpoint in $breakpoints {
    $query: map-get($breakpoint, 'query');
    $sizes: map-get($breakpoint, 'sizes');

    @include media($query...) {
      font-size: nth($sizes, 1);
      line-height: nth($sizes, 2);
    }
  }
}
