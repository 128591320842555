.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: rgba($cod-gray, .5);
  line-height: 50px;
  padding-left: 20px;
}

.select2-container .select2-selection--single {
  border: 1px solid $gainsboro;
  height: 50px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  &:after {
    @extend %chevron-black-right;
    transform: rotate(90deg);
    width: 20px;
    height: 20px;
    content: '';
    position: absolute;
    right: 10px;
    top: 15px;
  }
  b {
    display: none;
  }
}

.select2-container--default .select2-search--dropdown .select2-search__field,
.select2-dropdown {
  color: rgba($cod-gray, .5);
  border-color: $gainsboro;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  color: rgba($cod-gray, .5);
  background-color: $gainsboro;
  font-family: $font-family__sans-serif--bold;
  font-weight: normal;
}

.select2-selection__rendered,
.select2-results__option {
  span img {
    margin-right: 10px;
    position: relative;
    top: 3px;
  }
}
.select2-results__option {
  span img {
    margin-left: 14px;
  }
}
