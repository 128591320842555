.nav-sections {
  background: $white;
  border-bottom: 1px solid $white-lilac;
  margin: 0;
  .navigation {
    background: $white;
    height: 50px;
    z-index: 4;
    @include media('<=tablet') {
      height: auto;
    }
    > ul {
        padding: 0;

      > li:first-child {
        background: none;
        margin: 0 18px 0 0;
        &.home a {
          display: block;
          position: relative;
          height: 20px;
          width: 20px;
          &:before {
            @extend %house;
            content: '';
            height: 20px;
            position: absolute;
            top: 3px;
            width: 20px;
          }
          &:hover {
            &:before {
              @extend %house-green;
              content: '';
              height: 20px;
              position: absolute;
              top: 3px;
              width: 20px;
            }
          }
          &:after {
            display: none;
          }
          span {
            @include visible-for-screenreader();
          }
        }
      }
    }

    li.level1.parent {
      //position: relative;
      > a {
        position: relative;
        width: 100%;
        &:before {
          @extend %chevron-gray-right;
          content: '';
          height: 20px;
          position: absolute;
          top: 7px;
          right: 10px;
          width: 20px;
          display: block;
          float: right;
          @include media("<=phone") {
            top: 15px;
            right: 36px;
            transform: rotate(90deg);
          }
          &:hover {
            @extend %chevron-green-right;
          }
        }
      }
    }

    .level-top.parent {
      @include media('>phone') {
        &:after {
          background: url('~theme-assets/svg/arrow/chevron-gray-down.svg')
            no-repeat center;
          background-size: cover;
          height: 10px;
          right: -5px;
          top: 30px;
          width: 10px;
          content: '';
          display: block;
          position: absolute;
          z-index: 99;
        }
      }
      &:hover {
        &::after {
          background: url('~theme-assets/svg/arrow/chevron-green-down.svg')
            no-repeat center;
          background-size: cover;
          height: 10px;
          right: -5px;
          top: 30px;
          width: 10px;
          content: '';
          display: block;
          position: absolute;
          z-index: 99;
        }
      }
    }

    li.level0 {
      background-color: transparent;
      margin: 0 30px 0 0;
      padding: 0;
      @include media('<=large') {
        margin: 0 30px 0 0;
      }
      @media screen and (min-width: 1200px) and (max-width: 1479px) {
        margin: 0 5px 0;
      }

      @include media('<=desktop') {
        margin: 0 15px 0 0;
      }
      @include media('<=tablet') {
        margin: 0 60px 0 0;
      }

      @include media('<=tablet') {
        background-image: none;
      }

      &.logo {
        float: none;
        margin:10px 0 0;
        width: 200px;

        > span{
          display: flex;
          align-items: center;

          svg{
            width: auto;
            height: 15px;
            margin-left: 5px;
            flex-shrink: 0;

            @include media('<=tablet'){
              height: 25px;
              margin-left: 10px;
            }
          }
        }

        @include media(">=tablet") {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin: 0;
          width: 115px;
        }

        p {
          margin: 0;
          display: flex;
          align-items: center;
          height: inherit;
        }

        &:hover {
          filter: grayscale(100%);
        }

        img {

          body:not(.gplighting):not(.gplightingfr):not(.gplightingde) & {
            width: auto;

            @media screen and (min-width: 1200px) {
              height: 19px;
            }
          }
        }
      }

      > .level-top {
        background: none;
        font-size: 15px;
        padding: 0 7px;
        @include media('<=tablet') {
          padding: 0;
        }
        color: rgba($mineshaft, 0.8);
      }

      @include media('>=phone') {
        &:hover {
          > .level-top:after {
            background: url('~theme-assets/svg/arrow/chevron-green.svg')
              no-repeat center;
          }
          > a {
            color: $christi;
          }
        }
      }

      .submenu {
        background-color: $white;
        border-color: $white-lilac;
        border-top-color: $gainsboro;
        a,
        a:hover,
        a.ui-state-focus {
          background-color: transparent;
          font-weight: 500;
        }
        li:hover {
          > a {
            background-color: transparent;
            color: $christi;
          }
        }
        .submenu.expanded {
          top: -1px !important;
        }
      }

      a {
        @include fontWeightBold();
        display: inline-block;
      }

      &.active > .level-top,
      &.has-active > .level-top {
        color: $christi;
        font-weight: 400;
        border: none;
      }
    }

    .level1.ui-menu-item.parent:after,
    .level2.ui-menu-item.parent:after {
      display: none;
      //@extend %chevron-gray-right;
      //content: '';
      //height: 20px;
      //position: absolute;
      //top: 15px;
      //right: 10px;
      //width: 20px;
      //&:hover {
      //  @extend %chevron-green-right;
      //}
    }
  }
}

.nav-sections .navigation li.level0 > .level-top{
  line-height: 50px;
}
