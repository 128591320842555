/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _spacer contains {less} for spacers
 *
 */

.#{$prefix}-spacer {
  height: $bf-margin;
}
