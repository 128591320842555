[data-content-type="products"] {
    .products-grid > .products {
        @include media("<phone") {

        }
    }

    li.item.product.product-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% * 1/4 - 14.25px);
        max-width: calc(100% * 1/4 - 14.25px);
        width: calc(100% * 1/4 - 14.25px);

        @include media('<phone') {
            padding: 0;
        }


        .product-item-info {
            @include media('<phone') {
                height: auto !important;
            }
        }

        &:nth-child(n) {
            margin-right: 19px;
            margin-left: 0;
        }

        @include media('<tablet') {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(100% * 1/2 - 14.5px);
            max-width: calc(100% * 1/2 - 14.5px);
            width: calc(100% * 1/2 - 14.5px);


            &:nth-child(n) {
                margin-right: 29px;
                margin-left: 0;
            }
        }

        @include media('<phone') {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 100%;
            max-width: 100%;
            width: 100%;
            margin-bottom: 2rem;
        }

        .product-image-photo {
            @include media('<phone') {
                max-height: 150px !important;
            }
        }
    }
}
