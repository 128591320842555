/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _driver contains {less} for driver blocks
 *
 */
.#{$prefix}-driver {
  font-size: 0;
  a {
    width: auto;
    display: inline-block;
    position: relative;
  }
  img {
    max-width: 100%;
    display: block;
  }
  &:hover {
    .#{$prefix}-driver-text {
      background: rgba(0, 0, 0, 0.6);
    }
  }
}

.#{$prefix}-driver-text {
  position: absolute;
  z-index: 10;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  padding: $bf-margin;
  background: black;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  font-size: $large_font_size;
  color: white;
  @extend %transition;
}
