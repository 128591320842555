/**
* BlueFoot
 *
 * Version 1.0
 * $author Hob Adams <hob$gene.co.uk>
 *
 * _var contains core variables used throughout the BlueFoot {less} files
 *
 */

$prefix: "bluefoot";

/*-- Layout Variables --*/

$wrapper: 1260px;
$wrapper_wide: 1440px;
$margin: 15px;
$common_height: 40px;
$small_height: 15px;

/*-- Column Variables --*/

$column_count: 12;
$wrapper_width: 1260;

/*-- Responsive Sizes --*/

$wide: 1500px;
$desktop: 1024px;
$tablet: 990px;
$mobile: 770px;
$small: 550px;

/*-- Font Variables --*/

$small_size: 12px;
$regular_font_size: 14px;
$large_font_size: 18px;

/*-- Colours --*/

$theme_color_1: #3399cc;
$theme_color_2: #dddddd;

$border_color: #ededed;
$light_grey: #f6f6f6;
