.authentication-wrapper {
    float: inherit;
    margin: 30px 0 0 0;
    position: relative;
    text-align: left;
    width: auto;

    .action-auth-toggle {
        color: $christi;
    }

    > p {
        @extend %p--light;
    }
}

.modal-custom-overlay {
    //background-color: rgba(255, 255, 255, .6);
}

.authentication-dropdown {
    border-color: $default-border-color;
    .block-title strong {
        @extend %h3;
        @extend %h3--primary;
    }

    .column:not(.sidebar-additional) & form .actions-toolbar {
        margin-left: 0;
        .primary button {
            @extend %button-primary;
        }
        .secondary a {
            @extend %button-ghost-primary;
            margin-top: 0;
        }
    }
}

.block-authentication .block[class] .field .control {
    float: left;
    width: 74.2%;
}

.block-authentication .block[class] .field .label {
    float: left;
    width: 25.8%;
}

.authentication-dropdown{
    transition: transform linear .15s, visibility 0s linear .15s;
    &._show{
        transition: transform linear .15s, visibility 0s linear .15s;
    }
}