$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);

$gold: rgb(255, 215, 0);
$ucla-gold: rgb(255, 181, 0);

$christi: rgb(99, 167, 10);
$saltpan: rgb(238, 245, 229);
$lima: rgb(129, 195, 14);
$fern: rgb(68, 138, 5);

$primary: #63a70a;

$harvard-crimson: rgb(205, 0, 21);

$gainsboro: rgb(221, 221, 221);
$mercury: rgb(228, 228, 228);
$concrete: rgb(208, 208, 208);
$aluminum: rgb(151, 151, 151);
$white-lilac: rgb(231, 231, 231);
$grey-border: rgb(228, 228, 228);
$desert-storm: rgb(248, 248, 248);
$mineshaft: rgb(51, 51, 51);
$cod-gray: rgb(48, 48, 48);
$rangoon-green: rgb(27, 27, 27);
$chicago: rgb(91, 96, 87);
$border-top-gray: rgba(0, 0, 0, .05);
$thumbnail-border: #55a800;

/* Messages */
$error-bg: #fff1f0;
$error-content: #e1004c;
$infobox-bg: #ebf7fd;
$infobox-content: #0483b3;
$succes-bg: #f2fae2;
$succes-content: #66ad21;
$warning-bg: #fffbe0;
$warning-content: #fd9b00;

/* Defaults */
$default-font-color: $mineshaft;
$default-border-color: $gainsboro;
$default-form-element-color: $cod-gray;

/* Placeholders */
%primary-shadow {
  box-shadow: inset 1px 1px 2px 0 rgba($black, .1);
}

%primary-gradient {
  background: $christi;
  background-image: linear-gradient(90deg, $lima 1%, rgba($christi, 1) 100%);
}

%secundary-shadow {
  box-shadow: 0 2px 3px 0 rgba($black, .05), inset 0 1px 0 0 rgba($white, .25);
}

%secondary-gradient {
  background: $gold;
  background-image: linear-gradient(90deg, $ucla-gold 3%, rgba($gold, 1) 98%);
}
