/**
* BlueFoot
 *
 * Version 1.0
 * @author Hob Adams <hob@gene.co.uk>
 *
 * _accordion contains {less} for accordions
 *
 */

.#{$prefix}-accordion {
  .bluefoot-accordion-link,
  h4.bluefoot-accordion-link {
    cursor: pointer;
    margin: 0;
    padding: $bf-margin 0;
    box-sizing: border-box;
    line-height: 1.4em;
    &:after {
      content: '+';
      @extend %#{$prefix}-icons;
      float: right;
      font-size: 1.4em;
      width: 20px;
    }
  }

  .bluefoot-accordion-inner {
    box-sizing: border-box;
    padding: $bf-margin 0;
    border-top: 1px solid $bf-border_color;
  }
}

.#{$prefix}-accordion-item {
  border-bottom: 1px solid $bf-border_color;
  margin: 0;
  &.active {
    .bluefoot-accordion-link:after {
      content: '-';
    }
  }
}
